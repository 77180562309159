import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export const FolderDownloadIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{ fontSize: props.fontSize ? props.fontSize : 12 }}
      htmlColor={props.color ? props.color : "#98A5B3"}
      {...props}
    >
      <svg viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.4167 9.33333L16.8364 6.35856C16.3815 5.5024 16.1541 5.07431 15.8148 4.76155C15.5148 4.48497 15.1532 4.27463 14.7561 4.14575C14.3072 4 13.7987 4 12.7816 4H7.36671C5.77989 4 4.98648 4 4.3804 4.29065C3.84728 4.54631 3.41383 4.95426 3.14219 5.45603C2.83337 6.02646 2.83337 6.77319 2.83337 8.26667V9.33333M2.83337 9.33333H24.3667C26.7469 9.33333 27.937 9.33333 28.8462 9.76931C29.6459 10.1528 30.296 10.7647 30.7035 11.5174C31.1667 12.373 31.1667 13.4931 31.1667 15.7333V21.6C31.1667 23.8402 31.1667 24.9603 30.7035 25.816C30.296 26.5686 29.6459 27.1805 28.8462 27.564C27.937 28 26.7469 28 24.3667 28H9.63337C7.25315 28 6.06304 28 5.15391 27.564C4.35423 27.1805 3.70406 26.5686 3.2966 25.816C2.83337 24.9603 2.83337 23.8402 2.83337 21.6V9.33333ZM12.75 18.6667L17 22.6667M17 22.6667L21.25 18.6667M17 22.6667V14.6667"
          stroke={props.color ? props.color : "#98A5B3"}
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
