import React, { useEffect, useState } from "react";
import { PortletHeaderCustom } from "@/pages/my-progress/PortletHeaderCustom";
import { Portlet, PortletBody } from "@/components/content/Portlet";
import { TextField, Autocomplete } from "@mui/material";
import { useLearningResource } from "@/utils/ResourceGet";
import axios from "axios";
import { extractErrorMessage, getConfig } from "@/utils/misc";
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "material-ui-snackbar-provider";
import { useTranslation } from "react-i18next";
import { useUserContext } from "@/utils/contexts/UserContext";

export function LearningLanguage() {
  const [languageList] = useLearningResource("languages/", []);
  const [userLanguage] = useLearningResource("languages/current/", null);
  const [language, setLanguage] = useState({});
  const { uses_formal_tone } = useUserContext();
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const snackbar = useSnackbar();

  const displayedLanguageList = languageList.filter((language) =>
    uses_formal_tone ? language.code !== "fr" : language.code !== "fr_formal",
  );

  useEffect(() => {
    setLanguage(userLanguage);
  }, [userLanguage]);

  const changeLanguage = async (event) => {
    const newLanguage = { language: event };
    try {
      const result = await axios.post(
        import.meta.env.VITE_APP_ENDPOINT_LEARNING + "/languages/current/",
        newLanguage,
        await getConfig(getAccessTokenSilently),
      );
      setLanguage(result.data);
      snackbar.showMessage(t("Learning language updated successfully."));
    } catch (error) {
      snackbar.showMessage(extractErrorMessage(error));
    }
  };

  const getOptionLabel = (language) => {
    if (language.code === "fr_formal") {
      return `fr | French`;
    }
    return `${language?.code} | ${language?.value}`;
  };

  return (
    <>
      {userLanguage && (
        <Portlet>
          <PortletHeaderCustom title={t("My language for awareness courses")} />
          <PortletBody>
            <Autocomplete
              options={displayedLanguageList}
              value={language}
              getOptionLabel={getOptionLabel}
              isOptionEqualToValue={(option) =>
                displayedLanguageList.length &&
                displayedLanguageList.findIndex((o) => o === option) >= 0
              }
              className="mt-3"
              onChange={(e, value) => {
                changeLanguage(value?.code);
              }}
              renderInput={(params) => (
                <TextField {...params} label={t("Learning Language")} />
              )}
            />
          </PortletBody>
        </Portlet>
      )}
    </>
  );
}
