import { StyledBox } from "@/pages/awareness/setup/Teams/StyledBox";
import { StyledTypo } from "@/pages/awareness/setup/Teams/StyledTypo";
import { TeamsIcon } from "@/pages/awareness/setup/Teams/TeamsIcon";
import { Button, Typography } from "@mui/material";
import React from "react";

export const DocumentationComponent = () => {
  const openTeamsDocumentation = () => {
    window.open(
      "https://scribehow.com/shared/Install_Alex_Bot_on_Teams__PJCVKUY8SNmipPlbb0zv5Q",
      "_blank",
    );
  };

  return (
    <React.Fragment>
      <StyledTypo variant={"h2"} sx={{ py: "2rem" }}>
        Install bot on Teams
      </StyledTypo>
      <StyledBox>
        <StyledBox sx={{ justifyContent: "unset", width: "600px" }}>
          <Button
            sx={{ width: "11rem" }}
            variant={"outlined"}
            onClick={openTeamsDocumentation}
            startIcon={<TeamsIcon />}
          >
            Add to teams
          </Button>
          <Typography sx={{ paddingLeft: "3rem" }} color={"text.secondary"}>
            Add the Alex app to your company Microsoft Teams
          </Typography>
        </StyledBox>
      </StyledBox>
    </React.Fragment>
  );
};
