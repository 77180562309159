import React from "react";
import { SvgIcon } from "@mui/material";

export default function () {
  return (
    <SvgIcon>
      <svg
        width="27"
        height="24"
        viewBox="0 0 27 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.3514 0.946289C17.0981 0.946289 21.7568 5.60495 21.7568 11.3517C21.7568 17.0984 17.0981 21.7571 11.3514 21.7571C5.60462 21.7571 0.945961 17.0984 0.945961 11.3517C0.945961 5.60495 5.60462 0.946289 11.3514 0.946289ZM2.88981 10.4057C3.28056 6.87097 5.83537 3.98842 9.20001 3.11234C7.75592 5.28291 6.88111 7.79192 6.66902 10.4057H2.88981ZM2.88981 12.2976C3.28056 15.8324 5.83537 18.715 9.20001 19.591C7.75592 17.4205 6.88111 14.9115 6.66902 12.2976H2.88981ZM13.5027 19.591C16.8674 18.715 19.4222 15.8324 19.8129 12.2976H16.0337C15.8216 14.9115 14.9468 17.4205 13.5027 19.591ZM19.8129 10.4057H16.0337C15.8216 7.79192 14.9468 5.28291 13.5027 3.11234C16.8674 3.98842 19.4222 6.87097 19.8129 10.4057ZM11.3514 3.3592C12.9308 5.39406 13.8971 7.83924 14.1347 10.4057H8.56801C8.80562 7.83924 9.77194 5.39406 11.3514 3.3592ZM11.3514 19.3442C9.77194 17.3093 8.80562 14.8642 8.56801 12.2976H14.1347C13.8971 14.8642 12.9308 17.3093 11.3514 19.3442Z"
          fill="#7566FE"
        />
        <circle cx="20.4324" cy="18.1619" r="5.67561" fill="white" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.4324 12.4862C17.2978 12.4862 14.7567 15.0273 14.7567 18.1619C14.7567 21.2965 17.2978 23.8376 20.4324 23.8376C23.567 23.8376 26.1081 21.2965 26.1081 18.1619C26.1081 15.0273 23.567 12.4862 20.4324 12.4862ZM23.1191 16.9788C23.3206 16.7773 23.3206 16.4506 23.1191 16.2491C22.9176 16.0476 22.5909 16.0476 22.3894 16.2491L19.6584 18.9801L18.4754 17.797C18.2739 17.5955 17.9472 17.5955 17.7457 17.797C17.5442 17.9985 17.5442 18.3252 17.7457 18.5267L19.2936 20.0746C19.4951 20.2761 19.8218 20.2761 20.0233 20.0746L23.1191 16.9788Z"
          fill="#65C58B"
        />
      </svg>
    </SvgIcon>
  );
}
