import * as React from "react";
import {
  Checkbox,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

const protectionTypes = [
  {
    key: "flexible",
    label: "Prevent with bypass",
    description: {
      password: "User can manage their own trusted domains list",
      browsing: "User can navigate to page despite alerts",
      download: "User can proceed with blocked file download",
    },
  },
  {
    key: "strict",
    label: "Prevent",
    description: {
      password: "User cannot disable password alerts on any domain",
      browsing: "User cannot navigate to page in case of alert",
      download: "User cannot proceed with blocked file download",
    },
  },
  {
    key: "off",
    label: "Off",
    description: {
      password: "Feature disabled",
      browsing: "Feature disabled",
      download: "Feature disabled",
    },
  },
];

export default function CompanyConfigurationForm({
  record = null,
  formType = "company",
  groups = null,
}) {
  const [userCanDisableExtension, setUserCanDisableExtension] = React.useState(
    record ? record.user_can_disable_extension : false,
  );

  return (
    <Table>
      <TableBody>
        {groups && (
          <TableRow>
            <TableCell style={{ align: "left", width: "40%" }}>
              Group name
            </TableCell>
            <TableCell style={{ align: "left" }}>
              <Select
                style={{ width: "100%" }}
                name="group_id"
                size="small"
                defaultValue={""}
              >
                {groups.map((item) => (
                  <MenuItem key={item.group_id} value={item.group_id}>
                    {item.group_name}
                  </MenuItem>
                ))}
              </Select>
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell style={{ align: "left", width: "40%" }}>
            Password Protection
          </TableCell>
          <TableCell style={{ align: "left" }}>
            <Select
              style={{ width: "100%" }}
              name="password_protection"
              defaultValue={record ? record.password_protection : ""}
              size="small"
              renderValue={(selected) => {
                const selectedItem = protectionTypes.find(
                  (item) => item.key === selected,
                );
                return selectedItem ? selectedItem.label : "";
              }}
            >
              {protectionTypes.map((item) => (
                <MenuItem key={item.key} value={item.key}>
                  <div>
                    <Typography>{item.label}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {item.description["password"]}
                    </Typography>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ align: "left" }}>Downloads Protection</TableCell>
          <TableCell style={{ align: "left" }}>
            <Select
              style={{ width: "100%" }}
              name="downloads_protection"
              defaultValue={record ? record.downloads_protection : ""}
              size="small"
              renderValue={(selected) => {
                const selectedItem = protectionTypes.find(
                  (item) => item.key === selected,
                );
                return selectedItem ? selectedItem.label : "";
              }}
            >
              {protectionTypes.map((item) => (
                <MenuItem key={item.key} value={item.key}>
                  <div>
                    <Typography>{item.label}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {item.description["download"]}
                    </Typography>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ align: "left" }}>Browsing Protection</TableCell>
          <TableCell>
            <Select
              style={{ width: "100%" }}
              name="browsing_protection"
              defaultValue={record ? record.browsing_protection : ""}
              size="small"
              renderValue={(selected) => {
                const selectedItem = protectionTypes.find(
                  (item) => item.key === selected,
                );
                return selectedItem ? selectedItem.label : "";
              }}
            >
              {protectionTypes.map((item) => (
                <MenuItem key={item.key} value={item.key}>
                  <div>
                    <Typography>{item.label}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {item.description["browsing"]}
                    </Typography>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>User can disable extension</TableCell>
          <TableCell>
            <Checkbox
              style={{ display: "inline-flex" }}
              name="user_can_disable_extension"
              checked={userCanDisableExtension}
              onChange={() =>
                setUserCanDisableExtension(!userCanDisableExtension)
              }
            />
          </TableCell>
        </TableRow>
        {formType === "company" && (
          <TableRow>
            <TableCell>Authorized login domain(s)</TableCell>
            <TableCell>
              <TextField
                style={{ width: "100%" }}
                size="small"
                name="safe_domains"
                defaultValue={record ? record.safe_domains : ""}
                helperText="Multiple values, separated by comma"
              />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
