import React from "react";
import { Button } from "@mui/material";

export function ConsentButton({ email, app, details }) {
  const url =
    "https://login.microsoftonline.com/organizations/v2.0/adminconsent";
  const params = {
    client_id: app.clientId,
    scope: "https://graph.microsoft.com/.default",
    redirect_uri: `${
      import.meta.env.VITE_APP_ENDPOINT_SIMULATION
    }/office/consent/${app.name}`,
    state: email,
  };
  const urlParams = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
  return (
    <Button
      variant="outlined"
      color="neutral"
      onClick={() => window.open(url + "?" + urlParams, "_blank")}
    >
      Grant&nbsp;<strong>{details}</strong>&nbsp;Consent to Mantra
    </Button>
  );
}
