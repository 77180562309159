import React from "react";
import { Stack, Typography } from "@mui/material";
import StickyHeader from "@/components/StickyHeader";
import { TemplateSearchbar } from "./TemplateSearchbar";

export const TemplateHeader = () => {
  return (
    <>
      <StickyHeader height="110px">
        <Stack direction="column" gap={3}>
          <Typography
            variant="h2"
            style={{
              maxHeight: "2rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            Template Catalog
          </Typography>
          <TemplateSearchbar />
        </Stack>
      </StickyHeader>
    </>
  );
};
