export interface UserWithPermissions {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  permissions: Array<Permission>;
}

export enum PermissionCategory {
  SIMULATION = "simulation",
  AWARENESS = "awareness",
  BANNERS = "banners",
  BROWSER = "browser",
  THREATS = "threats",
  SETTINGS = "general-settings",
  PERMISSIONS_ATTRIBUTION = "permissions",
  CORPORATE = "corporate-admin",
}

export const CategoryTitle = new Map<PermissionCategory, string>([
  [PermissionCategory.SIMULATION, "Phishing Simulation"],
  [PermissionCategory.AWARENESS, "Cyber Awareness"],
  [PermissionCategory.BANNERS, "Smart Banners"],
  [PermissionCategory.BROWSER, "Browser Defender"],
  [PermissionCategory.THREATS, "Threats"],
  [PermissionCategory.SETTINGS, "General Settings"],
  [PermissionCategory.PERMISSIONS_ATTRIBUTION, "Permissions"],
  [PermissionCategory.CORPORATE, "Corporate"],
]);

export class Permission {
  public static readonly SIMULATION_DASHBOARD = new Permission(
    "simulation:dashboard",
    "Dashboard",
    "Access to your company's Phishing Simulation dashboard",
    PermissionCategory.SIMULATION,
    true,
  );
  public static readonly SIMULATION_CUSTOM_CAMPAIGN = new Permission(
    "simulation:custom-campaign",
    "Custom Campaign",
    "Create and send custom Phishing Simulation campaigns",
    PermissionCategory.SIMULATION,
    true,
  );
  public static readonly SIMULATION_TEMPLATE_CATALOG = new Permission(
    "simulation:template-catalog",
    "Template Catalog",
    "Access to template catalog",
    PermissionCategory.SIMULATION,
    false,
  );
  public static readonly SIMULATION_AUDIT = new Permission(
    "simulation:audit",
    "Audit",
    "Access to phishing simulation audit page",
    PermissionCategory.SIMULATION,
    true,
  );
  public static readonly SIMULATION_SETUP = new Permission(
    "simulation:setup",
    "Setup",
    "Access to preferences and test simulation delivery",
    PermissionCategory.SIMULATION,
    true,
  );
  public static readonly AWARENESS_DASHBOARD = new Permission(
    "awareness:dashboard",
    "Dashboard",
    "Access to your company's Cyber Awareness dashboard",
    PermissionCategory.AWARENESS,
    true,
  );
  public static readonly AWARENESS_COURSE_CATALOG_ADMIN = new Permission(
    "awareness:course-catalog-admin",
    "Courses Admin",
    "Manage course content and assignments",
    PermissionCategory.AWARENESS,
    true,
  );
  public static readonly AWARENESS_SETUP = new Permission(
    "awareness:setup",
    "Setup",
    "Access to preferences and chatbot setup",
    PermissionCategory.AWARENESS,
    true,
  );
  public static readonly THREATS = new Permission(
    "threats",
    "Threats",
    "View and categorize real emails reported by users",
    PermissionCategory.THREATS,
    true,
  );
  public static readonly SETTINGS_COMPANY_SCAN = new Permission(
    "settings:company-scan",
    "Company Scan",
    "Manage company information, groups & departments mapping and Saas attribution",
    PermissionCategory.SETTINGS,
    true,
  );
  public static readonly SETTINGS_GENERAL = new Permission(
    "settings:general",
    "General Settings",
    "Access to users list and manage provisioning from your active directory",
    PermissionCategory.SETTINGS,
    true,
  );
  public static readonly CORPORATE_ADMIN = new Permission(
    "corporate-admin",
    "Corporate Admin",
    "Browse through all subsidiaries of your group and access to corporate aggregated dashboards",
    PermissionCategory.CORPORATE,
    true,
  );
  public static readonly BROWSER_DEFENDER = new Permission(
    "browser-defender",
    "Browser Defender",
    "Access to Browser Defender section in the app",
    PermissionCategory.BROWSER,
    true,
  );
  public static readonly BANNERS = new Permission(
    "banners",
    "Smart Banners",
    "Access to Smart Banners section in the app",
    PermissionCategory.BANNERS,
    true,
  );
  public static readonly EDIT_PERMISSIONS = new Permission(
    "settings:edit-permissions",
    "Edit Permissions",
    "Add and remove custom permissions to other users (you cannot modify your own)",
    PermissionCategory.PERMISSIONS_ATTRIBUTION,
    true,
  );

  // Non editable permissions
  public static readonly MY_PROGRESS = new Permission(
    "my-progress",
    "My Progress",
    "View your progress in the training",
    PermissionCategory.AWARENESS,
    false,
  );
  public static readonly BASIC_USER_PERMISSIONS = new Permission(
    "basic-user-permissions",
    "Basic User",
    "Basic user permissions",
    PermissionCategory.PERMISSIONS_ATTRIBUTION,
    false,
  );
  public static readonly BASIC_ADMIN_PERMISSIONS = new Permission(
    "basic-admin-permissions",
    "Basic Admin",
    "Basic admin permissions",
    PermissionCategory.PERMISSIONS_ATTRIBUTION,
    false,
  );
  public static readonly AWARENESS_COURSE_CATALOG_USER = new Permission(
    "awareness:course-catalog-user",
    "Course User",
    "Access to course content",
    PermissionCategory.AWARENESS,
    false,
  );
  public static readonly SUPPORT = new Permission(
    "support",
    "Support",
    "Access to support",
    PermissionCategory.SETTINGS,
    false,
  );

  private constructor(
    public readonly code: string,
    public readonly name: string,
    public readonly description: string,
    public readonly category: PermissionCategory,
    public readonly editable: boolean = false,
  ) {
    this.code = code;
    this.name = name;
    this.description = description;
    this.category = category;
    this.editable = editable;
  }

  private static readonly ALL_PERMISSIONS = [
    Permission.SIMULATION_DASHBOARD,
    Permission.SIMULATION_CUSTOM_CAMPAIGN,
    Permission.SIMULATION_TEMPLATE_CATALOG,
    Permission.SIMULATION_AUDIT,
    Permission.SIMULATION_SETUP,
    Permission.AWARENESS_DASHBOARD,
    Permission.AWARENESS_COURSE_CATALOG_ADMIN,
    Permission.AWARENESS_SETUP,
    Permission.THREATS,
    Permission.SETTINGS_GENERAL,
    Permission.SETTINGS_COMPANY_SCAN,
    Permission.EDIT_PERMISSIONS,
    Permission.CORPORATE_ADMIN,
    Permission.BROWSER_DEFENDER,
    Permission.BANNERS,
    Permission.MY_PROGRESS,
    Permission.BASIC_USER_PERMISSIONS,
    Permission.BASIC_ADMIN_PERMISSIONS,
    Permission.AWARENESS_COURSE_CATALOG_USER,
    Permission.SUPPORT,
  ];

  public static fromCode(code: string): Permission {
    return Permission.ALL_PERMISSIONS.find((p) => p.code === code);
  }

  public static all(): Array<Permission> {
    return Permission.ALL_PERMISSIONS;
  }

  public static allEditable(): Array<Permission> {
    return Permission.ALL_PERMISSIONS.filter((p) => p.editable);
  }
}
