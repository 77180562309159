import React, { useState, useEffect, useContext, JSX } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { UserContextType } from "@/types/user";
import { jwtDecode } from "jwt-decode";
import { setToken, setEmail, setFormalTone } from "./AuthSlice";
import store from "@/store";
import { Permission } from "@/pages/general-settings/permissions-attribution/permissions";
import i18n from "@/i18n";

export const defaultUserContext: UserContextType = {
  email: "",
  firstname: "",
  lastname: "",
  language: "en",
  companies: [],
  is_impersonate: false,
  is_admin: false,
  is_corporate_admin: false,
  permissions: [],
  base_company: {
    id: null,
    name: "",
    mailHost: "OFFICE",
    provider: "",
    domain: "",
    awareness_activated: false,
  },
  current_company: {
    id: null,
    name: "",
    mailHost: "OFFICE",
    provider: "",
    domain: "",
    awareness_activated: false,
  },
  is_authenticated: false,
  uses_formal_tone: false,
};

export const UserContext =
  React.createContext<UserContextType>(defaultUserContext);
export function UserContextProvider({ children }: { children: JSX.Element }) {
  const { getAccessTokenSilently, user, isLoading, isAuthenticated } =
    useAuth0();
  const [accessTokenLoading, setAccessTokenLoading] = useState(false);
  const [simulationUser, setSimulationUser] =
    useState<UserContextType>(defaultUserContext);
  useEffect(() => {
    async function f() {
      if (isLoading || !isAuthenticated) {
        return;
      }
      setAccessTokenLoading(true);
      const accessToken = await getAccessTokenSilently();
      const decoded = jwtDecode<{ permissions: Array<string> }>(accessToken);

      // put token in the auth redux store
      store.dispatch(setToken(accessToken));

      const isAdmin = decoded.permissions.includes("basic-admin-permissions");
      const isCorporateAdmin = decoded.permissions.includes("corporate-admin");
      try {
        const response = await axios.get(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/current_user/info",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        setSimulationUser({
          is_admin: isAdmin,
          is_corporate_admin: isCorporateAdmin,
          is_authenticated: true,
          permissions: decoded.permissions.map(Permission.fromCode),
          ...response.data,
        });
        store.dispatch(setEmail(response.data.email));
        store.dispatch(setFormalTone(response.data.uses_formal_tone));
        setAccessTokenLoading(false);
        setupAxiosDefaultAuthorization(accessToken);
        i18n.changeLanguage(response.data.language);
      } catch (e) {
        // if we can't reach simulation, fallback to the auth0 user
        // the auth0 user needs to exist
        if (user === undefined || user?.email === undefined) {
          throw Error("Error while fallback to auth0 user");
        }
        setSimulationUser({
          email: user.email,
          is_admin: isAdmin,
          is_corporate_admin: isCorporateAdmin,
          is_authenticated: true,
          language: "en",
          uses_formal_tone: false,
        });
        store.dispatch(setToken(user.email));
        setAccessTokenLoading(false);
      }
    }
    f();
  }, [
    getAccessTokenSilently,
    setSimulationUser,
    user,
    isLoading,
    isAuthenticated,
  ]);

  if (isLoading || accessTokenLoading) {
    return <div>loading...</div>;
  }

  return (
    <UserContext.Provider value={simulationUser}>
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(
      "useUserContext should be used only into a UserContextProvider",
    );
  }
  return context;
}

/*
 * Setup authorization header for axios automatically.
 * Will not work in the futur if we decide to refresh token.
 */
function setupAxiosDefaultAuthorization(accessToken: string) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
}
