import React, { useEffect } from "react";
import { useLearningResource } from "@/utils/ResourceGet";
import { Navigate } from "react-router-dom";
import { Webchat } from "@/pages/webchat/Webchat";
import { FullSizeBigSpinner } from "@/components/Spinner";

export function EnterpriseWebchat() {
  const [companyInfo, companyInfoError] = useLearningResource("company", {
    messaging_platform: null,
  });
  const [connectionInfo, connectionInfoError, , fetchConnectionInfo] =
    useLearningResource(
      "webchat/initialize_connection",
      {
        conversation_id: "",
        access_token: "",
        watermark: "",
        user_id: "",
        user_name: "",
      },
      false,
    );

  const [currentCourse, currentCourseError, , fetchCurrentCourse] =
    useLearningResource(
      "webchat/user_current_course",
      {
        current_course_title: "Alex from Mantra",
        interactions: [],
      },
      false,
    );

  const [, , loadingHistory] = useLearningResource("webchat/load_user_history");

  useEffect(() => {
    if (companyInfo?.messaging_platform === "webchat") {
      fetchConnectionInfo();
      fetchCurrentCourse();
    }
  }, [companyInfo, fetchConnectionInfo, fetchCurrentCourse]);

  if (companyInfoError || connectionInfoError || currentCourseError) {
    return <div>An error occurred. Tech team has been notified.</div>;
  }

  if (!companyInfo.id) {
    return (
      <FullSizeBigSpinner
        shouldDisplayText={true}
        text="This may take a while..."
      />
    );
  } else {
    if (companyInfo?.messaging_platform === "webchat") {
      if (!connectionInfo.access_token) {
        return (
          <FullSizeBigSpinner
            shouldDisplayText={true}
            text="This may take a while..."
          />
        );
      }
    } else {
      return <Navigate to="/my-progress" />;
    }
  }

  return (
    <Webchat
      connectionInfo={connectionInfo}
      courseTitle={currentCourse.current_course_title}
      allInteractions={currentCourse.interactions}
      goBackPath="/awareness/courses"
      loadingHistory={loadingHistory}
    />
  );
}
