import React, { useEffect, useState } from "react";
import { Threats } from "@/pages/threats/Threats";
import { parseJwt } from "@/utils/misc";
import { useAuth0 } from "@auth0/auth0-react";
import { default as NgThreats } from "@/pages/threatsng/components/ThreatsNG";
import { Threats as NewThreats } from "@/pages/newthreats/Threats";
import { useIsNewThreatsUIEnabled } from "@/pages/threatsng/hooks/fetchers/fetch_new_ui_enabled";
import { FullSizeBigSpinner } from "@/components/Spinner";

// - old: legacy UI wired on Simulation backend
// - new: legacy UI wired on Threats backend
// - ng: new UI wired on Threats backend
type ThreatsFeatureFlag = "old" | "new" | "ng";

const bestDevs: string[] = [
  "bruno@mantra.ms",
  "gabriel@mantra.ms",
  "gaspard@mantra.ms",
  "guillaume@mantra.ms",
  "pierre.ribault@mantra.ms",
];

export const ThreatsFeatureGate = () => {
  const [feature, setFeature] = useState<ThreatsFeatureFlag | null>(null);
  const { getAccessTokenSilently } = useAuth0();
  const {
    isNewThreatsUIEnabled,
    threatNewUIEnabledIsLoading,
    threatNewUIEnabledIsError,
    fetchIsNewThreatsUIEnabled,
  } = useIsNewThreatsUIEnabled();

  useEffect(() => {
    fetchIsNewThreatsUIEnabled();
  }, [fetchIsNewThreatsUIEnabled]);

  useEffect(() => {
    const f = async () => {
      if (isNewThreatsUIEnabled == null) {
        return;
      }

      const accessToken = await getAccessTokenSilently();
      const decodedToken = parseJwt(accessToken);
      const userEmail = decodedToken["https://mantra.ms/email"];

      const isBestDev = bestDevs.includes(userEmail);
      const isFromMantra = userEmail.endsWith("@mantra.ms");

      if (isBestDev || isNewThreatsUIEnabled) {
        setFeature("ng");
      } else if (isFromMantra) {
        setFeature("new");
      } else {
        setFeature("old");
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    f();
  }, [isNewThreatsUIEnabled, getAccessTokenSilently]);

  useEffect(() => {
    if (threatNewUIEnabledIsLoading || threatNewUIEnabledIsError) {
      setFeature(null);
    }
  }, [threatNewUIEnabledIsLoading, threatNewUIEnabledIsError]);

  if (feature === null) {
    return <FullSizeBigSpinner />;
  }

  return feature === "ng" ? (
    <NgThreats />
  ) : feature === "new" ? (
    <NewThreats />
  ) : (
    <Threats />
  );
};
