import { Box, Button, Typography } from "@mui/material";
import React from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectSetupChecklistState } from "../SetupChecklistSlice";
import { MailHost } from "../SetupChecklistTypes";

const ProvisioningPermissions = () => {
  const { hasProvisioningPermissions, mailHost } = useSelector(
    selectSetupChecklistState,
  );

  return (
    <Box display="flex" flexDirection="column" gap="1.5rem">
      <Typography variant="h5">
        Allow Mantra to provision users using{" "}
        {mailHost === MailHost.OFFICE
          ? "Microsoft Entra ID"
          : "Google Workspace"}
      </Typography>
      <Typography>
        You need to provide Mantra with the user access permissions to import
        proper users
      </Typography>
      <Box display="flex" alignItems="center" gap="2.5rem">
        <Link
          to={
            hasProvisioningPermissions
              ? "#"
              : "/general-settings#users-provisioning"
          }
          style={
            hasProvisioningPermissions
              ? {
                  pointerEvents: "none",
                  cursor: "auto",
                }
              : {}
          }
        >
          <Button variant="contained" disabled={hasProvisioningPermissions}>
            Grant Provisioning Permissions
          </Button>
        </Link>
        <Box display="flex" gap="0.5rem">
          {hasProvisioningPermissions ? (
            <>
              <CheckCircleOutlinedIcon color="success" />
              <Typography variant="body_small" color="#2e7d32">
                It works!
              </Typography>
            </>
          ) : (
            <>
              <CancelOutlinedIcon color="error" />
              <Typography color="#ed3b5b">Not connected</Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ProvisioningPermissions;
