import StickyHeader from "@/components/StickyHeader";
import { Typography } from "@mui/material";
import React from "react";
import { SetupChecklist } from "./SetupChecklist";
import { Preferences } from "./Preferences";
import { DeliveryTest } from "./DeliveryTest";
import Tabs, {
  TabsContextProvider,
  useTabsContext,
} from "@/components/controls/Tabs";

const InnerSetup: React.FC = () => {
  const { currentTabComponent } = useTabsContext();

  return (
    <>
      <StickyHeader height="120px">
        <Typography variant="h2">Simulation Setup</Typography>
        <Tabs />
      </StickyHeader>
      {currentTabComponent}
    </>
  );
};

export const Setup: React.FC = () => {
  return (
    <TabsContextProvider
      value={{
        tabs: [
          {
            anchor: "setup-checklist",
            label: "Setup Checklist",
            component: <SetupChecklist />,
          },
          {
            anchor: "delivery-test",
            label: "Delivery Test",
            component: <DeliveryTest />,
          },
          {
            anchor: "preferences",
            label: "Preferences",
            component: <Preferences />,
          },
        ],
      }}
    >
      <InnerSetup />
    </TabsContextProvider>
  );
};
