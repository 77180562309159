import React from "react";
import { SvgIcon } from "@mui/material";

export default function () {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.1874 1.8916C10.5309 1.8916 7.56661 4.85585 7.56661 8.51244C7.56661 8.81355 7.58678 9.11046 7.62594 9.40178C7.65455 9.61459 7.6723 9.74777 7.68167 9.84484C7.68334 9.86215 7.68452 9.87637 7.68536 9.88781L7.67695 9.89713C7.63088 9.94772 7.56467 10.0143 7.44557 10.1334L2.61189 14.9671L2.57769 15.0011C2.45033 15.1278 2.28997 15.2873 2.17037 15.4825C2.06665 15.6517 1.99022 15.8363 1.94388 16.0293C1.89044 16.2519 1.89105 16.478 1.89153 16.6577L1.89161 16.7059L1.89161 18.3793C1.89158 18.618 1.89156 18.8505 1.90764 19.0473C1.92531 19.2636 1.96699 19.5187 2.09779 19.7754C2.27915 20.1313 2.56854 20.4207 2.92448 20.6021C3.1812 20.7329 3.43633 20.7746 3.65254 20.7922C3.84939 20.8083 4.08182 20.8083 4.32048 20.8083L6.62078 20.8083C7.14315 20.8083 7.56661 20.3848 7.56661 19.8624V18.9166H8.51245C9.03482 18.9166 9.45828 18.4931 9.45828 17.9708V17.0249H10.4041C10.655 17.0249 10.8955 16.9253 11.0729 16.7479L12.5665 15.2543C12.6856 15.1352 12.7522 15.069 12.8027 15.0229L12.8121 15.0145C12.8235 15.0154 12.8377 15.0165 12.855 15.0182C12.9521 15.0276 13.0853 15.0453 13.2981 15.0739C13.5894 15.1131 13.8863 15.1333 14.1874 15.1333C17.844 15.1333 20.8083 12.169 20.8083 8.51244C20.8083 4.85585 17.844 1.8916 14.1874 1.8916ZM14.1876 7.56643C14.4311 7.56643 14.6715 7.65858 14.8564 7.84345C15.0413 8.02832 15.1334 8.26872 15.1334 8.51223C15.1334 9.0346 15.5569 9.45805 16.0793 9.45804C16.6016 9.45802 17.0251 9.03455 17.0251 8.51218C17.0251 7.78749 16.7479 7.05969 16.194 6.50584C15.6401 5.95197 14.9123 5.67476 14.1876 5.67476C13.6652 5.67476 13.2418 6.09822 13.2418 6.62059C13.2418 7.14296 13.6652 7.56643 14.1876 7.56643Z"
          fill="#7566FE"
        />
        <circle cx="17.6756" cy="17.6756" r="5.67561" fill="white" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.6756 11.9999C14.541 11.9999 12 14.541 12 17.6755C12 20.8101 14.541 23.3512 17.6756 23.3512C20.8102 23.3512 23.3513 20.8101 23.3513 17.6755C23.3513 14.541 20.8102 11.9999 17.6756 11.9999ZM20.3623 16.4925C20.5638 16.291 20.5638 15.9643 20.3623 15.7628C20.1608 15.5613 19.8341 15.5613 19.6327 15.7628L16.9017 18.4938L15.7186 17.3107C15.5171 17.1092 15.1904 17.1092 14.9889 17.3107C14.7874 17.5122 14.7874 17.8389 14.9889 18.0404L16.5368 19.5883C16.7383 19.7898 17.065 19.7898 17.2665 19.5883L20.3623 16.4925Z"
          fill="#65C58B"
        />
      </svg>
    </SvgIcon>
  );
}
