import React, { useMemo, useState } from "react";
import TimeFrameSelector from "@/pages/phishing-simulation/dashboard/TimeFrameSelector";
import { useSimulationResource } from "@/utils/ResourceGet";
import BannerMailsProcessedChart from "@/pages/banners/dashboard/BannerMailsProcessedChart";
import BigNumber from "@/pages/phishing-simulation/dashboard/BasicStats/BigNumber";
import BannerMailsWithBannerChart from "@/pages/banners/dashboard/BannerMailsWithBannerChart";
import dayjs from "dayjs";
import { ComingSoon } from "@/pages/banners/ComingSoon";
import MediumBarChart from "@/pages/banners/dashboard/MediumBarChart";
import css from "@/pages/banners/dashboard/MediumBarChart.module.css";
import { FullSizeBigSpinner } from "@/components/Spinner";
import { useStats } from "@/pages/banners/remote-apis/bannerStatistics";

export function BannerDashboard() {
  const [company, errorCompany] = useSimulationResource("company", null);

  let content;
  if (errorCompany) {
    content = <div>{errorCompany?.message}</div>;
  } else if (company === null) {
    content = <FullSizeBigSpinner />;
  } else {
    if (company.banners_activated) {
      content = <AdminBannerDashboard />;
    } else {
      content = <ComingSoon />;
    }
  }

  return content;
}

function FakeDemoGraphs({ banners_types }: { banners_types: object }) {
  return (
    <div className={css.typesBannersDashboard}>
      <MediumBarChart
        data={banners_types["Company Impersonation"]}
        title={"Company Impersonation"}
      />
      <MediumBarChart
        data={banners_types["Employee Impersonation"]}
        title={"Employee Impersonation"}
      />
      <MediumBarChart
        data={banners_types["Contact Impersonation"]}
        title={"Contact Impersonation"}
      />
      <MediumBarChart
        data={banners_types["Brand Impersonation"]}
        title={"Brand Impersonation"}
      />
      <MediumBarChart
        data={banners_types["Contact Impersonation"]}
        title={"Suspicious attachments"}
      />
      <MediumBarChart
        data={banners_types["Suspicious File Transfer"]}
        title={"Suspicious file transfer"}
      />
      <MediumBarChart
        data={banners_types["Suspicious Financial Operation"]}
        title={"Suspicious financial operation"}
      />
      <MediumBarChart
        data={banners_types["Account Take-Over"]}
        title={"Account Take-Over"}
      />
    </div>
  );
}

function AdminBannerDashboard() {
  const [dateSinceBeginning] = useSimulationResource(
    "company/first_simulation_date",
    null,
  );
  const [dateFromNbMonthAgo, setDateFromNbMonthAgo] = useState(1);

  const now = useMemo(dayjs, []);
  const startDate = now.subtract(dateFromNbMonthAgo, "months");
  const endDate = now;
  const { data, error, isPending } = useStats(
    startDate.toDate(),
    endDate.toDate(),
  );

  if (error) {
    return <div>An error occurred</div>;
  } else if (isPending || data === null) {
    return (
      <FullSizeBigSpinner
        shouldDisplayText={true}
        text="This may take a while..."
      />
    );
  } else {
    return (
      <div>
        <div className="row flex-row-reverse">
          <TimeFrameSelector
            dateFromNbMonthAgo={dateFromNbMonthAgo}
            setDateFromNbMonthAgo={setDateFromNbMonthAgo}
            dateSinceBeginning={dateSinceBeginning}
            setSelectedPeriod={() => {}}
          />
        </div>
        <div className="d-flex justify-content-between flex-wrap">
          <BigNumber
            className="mr-2"
            title="Mailboxes monitored"
            value={data.mailboxesMonitored}
            height={175}
            compact={true}
          />
          <BigNumber
            className="mr-2"
            title="Emails processed"
            value={data.messagesProcessed}
            height={175}
            compact={true}
          />
          <BigNumber
            className="mr-2"
            title="Banners added"
            value={data.messagesWithBanner}
            height={175}
            compact={true}
          />
          <BigNumber
            className="mr-2"
            title="Percentage of banners added"
            value={
              data.messagesProcessed
                ? (
                    (data.messagesWithBanner / data.messagesProcessed) *
                    100
                  ).toFixed(2) + " %"
                : "-"
            }
            height={175}
          />
        </div>
        <BannerMailsProcessedChart
          data={data.dailyStatistics}
          title="Processed emails"
        />
        <BannerMailsWithBannerChart
          data={data.dailyStatistics}
          title="Emails with banners"
        />
        {data.bannerTypes && (
          <FakeDemoGraphs banners_types={data.bannerTypes} />
        )}
      </div>
    );
  }
}
