import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default function TimeFrameSelector({
  dateFromNbMonthAgo,
  setDateFromNbMonthAgo,
  dateSinceBeginning,
  setSelectedPeriod,
}) {
  const handleChange = (event) => {
    setDateFromNbMonthAgo(event.target.value);
    setSelectedPeriod((current) => ({ ...current, value: null }));
  };

  return (
    <>
      <FormControl
        sx={{
          width: "150px",
          background: dateFromNbMonthAgo !== 3 ? "#ebefff" : "",
        }}
      >
        <InputLabel size="small" htmlFor="timeframe-select">
          Timeframe
        </InputLabel>
        <Select
          label="Timeframe"
          id="timeframe-select"
          size="small"
          value={dateFromNbMonthAgo}
          onChange={handleChange}
        >
          <MenuItem value={1} key={1}>
            1 Month
          </MenuItem>
          <MenuItem value={3} key={3}>
            3 Months
          </MenuItem>
          <MenuItem value={6} key={6}>
            6 Months
          </MenuItem>
          <MenuItem value={12} key={12}>
            1 Year
          </MenuItem>
          {dateSinceBeginning ? (
            <MenuItem
              value={dateSinceBeginning.nb_months_since}
              key={dateSinceBeginning.nb_months_since}
            >
              Max
            </MenuItem>
          ) : (
            <MenuItem value={1} key={1}>
              Max
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </>
  );
}
