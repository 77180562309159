import React, { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton } from "@mui/material";
import axios from "axios";
import { extractErrorMessage } from "@/utils/misc";
import { useAuth0 } from "@auth0/auth0-react";
import { EmailTableComponent } from "@/pages/phishing-simulation/setup/EmailTableComponent";
import RefreshIcon from "@mui/icons-material/Refresh";

export function WhitelistingScenarios() {
  const [outboundRequest, setOutboundRequest] = useState(false);
  const [templates, setTemplatesList] = useState([]);
  const [showTestSent, setShowTestSent] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [errorMessageTest, setErrorMessageTest] = useState("");
  const { user, getAccessTokenSilently } = useAuth0();
  const tableDisplayOptions = {
    download: false,
    filter: false,
    pagination: false,
    print: false,
    search: false,
    selectableRows: "none",
    viewColumns: false,
    customToolbar: () => {
      return (
        <IconButton onClick={() => setShouldRefresh((prev) => !prev)}>
          <RefreshIcon />
        </IconButton>
      );
    },
  };

  const sendTest = async () => {
    const accessToken = await getAccessTokenSilently();
    try {
      setOutboundRequest(true);
      await axios.get(
        `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/test_email`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );
      setShowTestSent(true);
      setErrorMessageTest(null);
      setOutboundRequest(false);
    } catch (error) {
      setShowTestSent(false);
      setErrorMessageTest(extractErrorMessage(error));
      setOutboundRequest(false);
    }
  };

  const sendTemplate = async (
    template_name,
    template_language,
    false_banners = false,
  ) => {
    const accessToken = await getAccessTokenSilently();
    try {
      setOutboundRequest(true);

      await axios.post(
        `${
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION
        }/test_email_from_template`,
        {
          template_name: template_name,
          template_language: template_language,
          add_banner: false_banners,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );
      setShowTestSent(true);
      setErrorMessageTest(null);
      setOutboundRequest(false);
      setShouldRefresh((prev) => !prev);
    } catch (error) {
      setShowTestSent(false);
      setErrorMessageTest(extractErrorMessage(error));
      setOutboundRequest(false);
    }
  };

  useEffect(() => {
    const whitelisting_templates_call = async () => {
      const accessToken = await getAccessTokenSilently();
      const endpointPath = `${
        import.meta.env.VITE_APP_ENDPOINT_SIMULATION
      }/templates/whitelisting_tests`;
      const result = await axios.get(endpointPath, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      let templates = [
        {
          attachment: null,
          from_email: "team@mantra.ms",
          from_name: "Mantra Team",
          id: null,
          language__code: "fr",
          name: "Simple text email",
          scenario_template: "",
          send_date: "",
          status: "",
          subject: "Setup test",
          template_scenario: "simple_text_email", // This is used later to determine whether to display a Button or a SendButtonList
        },
      ].concat(result.data.templates);
      setTemplatesList(templates);
    };
    // noinspection JSIgnoredPromiseFromCall
    whitelisting_templates_call();
  }, [getAccessTokenSilently, shouldRefresh]);

  const additional_columns = [
    {
      name: "send_button",
      label: "",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => {
          return { style: { textAlign: "center" } };
        },
        customBodyRenderLite: (dataIndex) => {
          const template = templates[dataIndex];
          const isSimpleTemplate =
            template.template_scenario === "simple_text_email";
          const sendRealTemplate = () =>
            sendTemplate(template.name, template.language__code);

          return (
            <Button
              disableElevation
              variant="contained"
              disabled={outboundRequest}
              sx={{ fontWeight: "900", width: "89px" }}
              onClick={isSimpleTemplate ? sendTest : sendRealTemplate}
            >
              Send
            </Button>
          );
        },
        customHeadLabelRender: (columnMeta) => {
          return "";
        },
      },
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <Box sx={{ pb: 4 }}>
        Once you have set up your domain according to this{" "}
        <Box
          component="div"
          sx={{
            display: "inline",
            color: "#0000FF",
            fontWeight: 600,
            fontSize: "0.95rem",
          }}
        >
          <a href="https://www.notion.so/mantrams/Mantra-Setup-Guide-d8db591a14b54dd2a184f2287b917360">
            documentation
          </a>{" "}
        </Box>
        , check the delivery by sending yourself the templates below. If
        everything is correctly set up, you should be able to receive all the
        templates below in your inbox.
      </Box>

      <EmailTableComponent
        data={templates}
        additionalColumns={additional_columns}
        tableDisplayOptions={tableDisplayOptions}
      />

      <Box sx={{ p: 2, fontSize: "0.9rem", fontStyle: "italic" }}>
        If you do not want these simulation emails to be taken into account in
        your dashboard data, do not forget to ask us to clean them from our
        database.
      </Box>

      <Grid container>
        <Grid item>
          {showTestSent && (
            <div style={{ color: "green" }} className="mt-3">
              Email successfully sent to {user.email}.
            </div>
          )}
          {errorMessageTest && (
            <div style={{ color: "red" }}>{errorMessageTest}</div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
