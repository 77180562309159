import React from "react";
import { SvgIcon } from "@mui/material";

export default function () {
  return (
    <SvgIcon>
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.5135 10.8787L10.4054 12.7706L14.6621 8.51384M18.9189 11.3517C18.9189 15.9948 13.8543 19.3718 12.0116 20.4468C11.8022 20.569 11.6975 20.6301 11.5497 20.6618C11.435 20.6864 11.2677 20.6864 11.153 20.6618C11.0052 20.6301 10.9005 20.569 10.6911 20.4468C8.84833 19.3718 3.78377 15.9948 3.78377 11.3517V6.82779C3.78377 6.07149 3.78377 5.69334 3.90746 5.36829C4.01673 5.08114 4.19429 4.82491 4.4248 4.62177C4.68572 4.39182 5.03979 4.25905 5.74793 3.99349L10.8199 2.0915C11.0166 2.01776 11.1149 1.98088 11.216 1.96627C11.3058 1.9533 11.3969 1.9533 11.4866 1.96627C11.5878 1.98088 11.6861 2.01776 11.8828 2.0915L16.9547 3.99349C17.6629 4.25905 18.0169 4.39182 18.2779 4.62177C18.5084 4.82491 18.6859 5.08114 18.7952 5.36829C18.9189 5.69334 18.9189 6.07149 18.9189 6.82779V11.3517Z"
          stroke="#7566FE"
          stroke-width="1.89189"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
