import { useState, useEffect, useCallback, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";
import { sanitizeHTML } from "@/utils/misc";
import { adaptSortComponentToThreat, adaptSortToDjangoORM } from "./utils";
import { debounce } from "lodash";

const useFetchThreats = (isArchiveDisplayedByDefault: boolean) => {
  const { getAccessTokenSilently } = useAuth0();
  const [isArchiveDisplayed, setIsArchiveDisplayed] = useState<boolean>(
    isArchiveDisplayedByDefault,
  );
  const [isLoadingThreats, setIsLoadingThreats] = useState<boolean>(true);
  const [errorThreats, setErrorThreats] = useState<any | undefined>(undefined);
  const [fetchedThreats, setFetchedThreats] = useState<PaginatedThreats>();
  const [shouldFetch, setShouldFetch] = useState<boolean>(true);
  const [sortColumns, setSortColumns] = useState<SortColumn[]>([
    {
      key: "report_date",
      order: "desc",
    },
  ]);
  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>();
  const snackbar = useSnackbar();
  const [hidThreatsIds, setHidThreatsIds] = useState<number[]>([]);

  const setArchiveMode = useCallback(
    (v: boolean) => {
      setIsArchiveDisplayed(v);
      setPage(1);
      setHidThreatsIds([]);
      setShouldFetch(true);
    },
    [setIsArchiveDisplayed, setShouldFetch, setPage],
  );

  const nextPaginate = useCallback(() => {
    setPage((v) => v + 1);
    setShouldFetch(true);
  }, [setShouldFetch, setPage]);

  const prevPaginate = useCallback(() => {
    setPage((v) => {
      if (v > 1) {
        return v - 1;
      }
      return v;
    });
    setShouldFetch(true);
  }, [setShouldFetch, setPage]);

  const fetchThreats = useCallback(
    () => setShouldFetch(true),
    [setShouldFetch],
  );

  useEffect(() => {
    const debouncedFetch = debounce(async () => {
      if (!shouldFetch) {
        return;
      }
      const accessToken = await getAccessTokenSilently();
      const endpoint = new URL(
        `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/threats`,
      );

      endpoint.searchParams.append("archived", String(isArchiveDisplayed));
      endpoint.searchParams.append("current_page", String(page));
      for (const sort of adaptSortToDjangoORM(sortColumns)) {
        endpoint.searchParams.append("sort", sort);
      }
      if (searchTerm != null && searchTerm !== "") {
        endpoint.searchParams.append("search", searchTerm);
      }
      endpoint.searchParams.append(
        "nb_hidden_threats",
        String(hidThreatsIds.length),
      );

      try {
        setShouldFetch(false);
        setIsLoadingThreats(true);
        const fetchedThreats = await axios.get(endpoint.href, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        let data = await fetchedThreats.data;
        setFetchedThreats(data);
      } catch (e) {
        setErrorThreats(e);
        snackbar.showMessage("unable to fetch threats");
      } finally {
        setIsLoadingThreats(false);
        setHidThreatsIds([]);
      }
    }, 150);
    debouncedFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    snackbar,
    shouldFetch,
    searchTerm,
    isArchiveDisplayed,
    sortColumns,
    getAccessTokenSilently,
    setShouldFetch,
    setIsLoadingThreats,
    setFetchedThreats,
    setErrorThreats,
  ]);

  useEffect(() => {
    setShouldFetch(true);
  }, [searchTerm]);

  const onSort = useCallback(
    (sortArray) => {
      let formattedSortArray: SortColumn[] = [];
      if (sortArray.length === 0) {
        formattedSortArray = [
          {
            order: "desc",
            key: "report_date",
          },
        ];
      } else {
        formattedSortArray = adaptSortComponentToThreat(sortArray);
      }
      setSortColumns(formattedSortArray);
      setShouldFetch(true);
      setPage(1);
    },
    [setSortColumns, setShouldFetch],
  );

  return {
    fetchedThreats,
    setShouldFetch,
    setArchiveMode,
    isArchiveDisplayed,
    isLoadingThreats,
    errorThreats,
    fetchThreats,
    nextPaginate,
    prevPaginate,
    onSort,
    setSearchTerm,
    hidThreatsIds,
    setHidThreatsIds,
  };
};

const useFetchThreatDetails = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [threatDetails, setThreatDetails] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [shouldFetch, setShouldFetch] = useState<boolean>(true);
  const [id, setId] = useState<number | null>(null);
  const snackbar = useSnackbar();

  const fetchThreatDetails = (id: number) => {
    setId(id);
    setShouldFetch(true);
  };

  const resetThreatDetails = useCallback(() => {
    setThreatDetails({});
  }, [setThreatDetails]);

  useEffect(() => {
    if (id == null || !shouldFetch) {
      return;
    }
    (async () => {
      const accessToken = await getAccessTokenSilently();
      try {
        setShouldFetch(false);
        setIsLoading(true);
        const endpoint = new URL(
          `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/threat/${id}`,
        );
        const fetchedThreats = await axios.get(endpoint.href, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        const data = await fetchedThreats.data;
        setThreatDetails({ ...data, body: sanitizeHTML(data.body) });
      } catch (e) {
        setError(e);
        setThreatDetails({});
        snackbar.showMessage("unable to fetch threat details");
      } finally {
        setIsLoading(false);
      }
    })();
  }, [
    id,
    snackbar,
    shouldFetch,
    getAccessTokenSilently,
    setShouldFetch,
    setIsLoading,
    setThreatDetails,
    setError,
  ]);

  return {
    threatDetails,
    isLoadingThreatDetails: isLoading,
    errorThreatDetails: error,
    fetchThreatDetails,
    resetThreatDetails,
  };
};

export function useThreats() {
  const {
    fetchedThreats,
    setArchiveMode,
    isArchiveDisplayed,
    isLoadingThreats,
    nextPaginate,
    prevPaginate,
    onSort,
    setSearchTerm,
    hidThreatsIds,
    setHidThreatsIds,
  } = useFetchThreats(false);
  const {
    threatDetails,
    isLoadingThreatDetails,
    fetchThreatDetails,
    resetThreatDetails,
  } = useFetchThreatDetails();

  const rowPerPages = 50;

  const { getAccessTokenSilently } = useAuth0();
  const snackbar = useSnackbar();
  const [selectedColumns, setSelectedColumns] = useState<number[]>([]);
  const [isUpdatingSelectedThreats, setIsUpdatingSelectedThreats] =
    useState<boolean>(false);
  const [activeId, setActiveId] = useState<number | null>(null);

  const displayedThreats = useMemo(() => {
    setSelectedColumns([]);
    const hidThreats = [...(fetchedThreats?.threats ?? [])].filter(
      (el) => !hidThreatsIds.includes(el.id),
    );
    return hidThreats;
  }, [fetchedThreats, hidThreatsIds, setSelectedColumns]);

  const selectAfterUpdate = useCallback(() => {
    let lastId: number | null = null;
    if (selectedColumns.length > 0) {
      lastId = selectedColumns[selectedColumns.length - 1];
    }
    const threatId: number = displayedThreats.findIndex((x) => x.id === lastId);
    if (displayedThreats.length === 0) {
      setSelectedColumns([]);
      setActiveId(null);
    } else if (threatId === -1 || threatId >= displayedThreats.length - 1) {
      setSelectedColumns([displayedThreats[displayedThreats.length - 2]?.id]);
      setActiveId(displayedThreats[displayedThreats.length - 2]?.id);
    } else {
      setSelectedColumns([displayedThreats[threatId + 1].id]);
      setActiveId(displayedThreats[threatId + 1].id);
    }
  }, [setSelectedColumns, selectedColumns, displayedThreats]);

  const updateSelectedThreats = useCallback(
    async (status: ThreatState | "delete", comment: string = "") => {
      if (isUpdatingSelectedThreats) {
        return;
      }
      const payload = {
        threat_ids: selectedColumns,
        comment: comment,
        status: status,
      };
      if (status === "delete" || status === "unknown") {
        delete payload.status;
      }
      let endpoint = "feedback";
      if (status === "delete") {
        endpoint = "delete";
      } else if (status === "unknown") {
        endpoint = "unarchive";
      }

      setIsUpdatingSelectedThreats(true);
      try {
        const accessToken = await getAccessTokenSilently();
        await axios.post(
          `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/threat/${endpoint}`,
          payload,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          },
        );
        setHidThreatsIds((ids) => [...ids, ...selectedColumns]);
        selectAfterUpdate();
      } catch (e) {
        snackbar.showMessage("Unable to mark feedback");
      } finally {
        setIsUpdatingSelectedThreats(false);
      }
    },
    [
      isUpdatingSelectedThreats,
      selectedColumns,
      selectAfterUpdate,
      snackbar,
      getAccessTokenSilently,
      setIsUpdatingSelectedThreats,
      setHidThreatsIds,
    ],
  );

  useEffect(() => {
    setSelectedColumns([]);
    setHidThreatsIds([]);
    setActiveId(null);
    resetThreatDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArchiveDisplayed, setSelectedColumns, setHidThreatsIds]);

  useEffect(() => {
    setSelectedColumns([]);
    setActiveId(null);
  }, [setSelectedColumns]);

  useEffect(() => {
    if (activeId == null) {
      resetThreatDetails();
    } else {
      fetchThreatDetails(activeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeId]);

  useEffect(() => {
    setHidThreatsIds([]);
  }, [isLoadingThreats, setHidThreatsIds]);

  return {
    threats: displayedThreats,
    totalThreatsCount: fetchedThreats?.count - (hidThreatsIds.length ?? 0) ?? 0,
    isLoadingThreats,
    isArchiveDisplayed,
    setArchiveMode,
    search: setSearchTerm,
    selectedColumns,
    setSelectedColumns,
    updateSelectedThreats,
    isUpdatingSelectedThreats,
    activeId,
    setActiveId,
    threatDetails,
    isLoadingThreatDetails,
    resetThreatDetails,
    offset: fetchedThreats?.start_index ?? 1,
    rowPerPages,
    nextPaginate,
    prevPaginate,
    onSort,
    numPages: fetchedThreats?.num_pages,
  };
}
