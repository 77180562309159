import React from "react";
import { Consent } from "@/pages/general-settings/Office/Consent";
import { Blacklist } from "@/pages/general-settings/commonUserProvisioning/Blacklist";
import { EnableCheckbox } from "@/pages/general-settings/commonUserProvisioning/EnableCheckbox";
import { ManualSync } from "@/pages/general-settings/commonUserProvisioning/ManualSync";
import { GroupWhitelist } from "@/pages/general-settings/GroupWhitelist";
import { Typography } from "@mui/material";
import { Portlet, PortletBody } from "@/components/content/Portlet";
import { useUserContext } from "@/utils/contexts/UserContext";

export function OfficeSync() {
  const user = useUserContext();

  return (
    <Portlet>
      <PortletBody>
        {user?.base_company.id === user?.current_company.id ? (
          <Typography style={{ color: "black" }} component={"span"}>
            <h4>Grant Consent</h4>
            <Consent />
            <br />
            <h4>Blacklisted users (optional)</h4>
            <Blacklist />
            <br />
            <GroupWhitelist />
            <br />
            <EnableCheckbox />
            <ManualSync />
          </Typography>
        ) : (
          <Typography color="error">
            You can only modify settings from {user?.base_company.name}
          </Typography>
        )}
      </PortletBody>
    </Portlet>
  );
}
