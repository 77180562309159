import React, { useEffect, useRef, useState } from "react";
import { extractErrorMessage } from "@/utils/misc";
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "material-ui-snackbar-provider";
import axios from "axios";
import { useUserContext } from "@/utils/contexts/UserContext";
import { TextareaAutosize } from "@mui/base";

export function GroupWhitelist() {
  const baseInfo = useUserContext();
  const companyProvider = baseInfo.current_company.provider;
  const [groupNames, setGroupNames] = useState("");
  const previousSerializedGroup = useRef(""); // Previous state of blacklisted groups
  const { getAccessTokenSilently } = useAuth0();
  const snackbar = useSnackbar();

  // Populate the groups
  useEffect(() => {
    const f = async () => {
      const accessToken = await getAccessTokenSilently();

      try {
        const result = await axios.get(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
            "/groups/sync/whitelist",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        // merge the group array to create a single string
        setGroupNames(result.data.whitelisted.join("\n"));
      } catch (error) {
        snackbar.showMessage(extractErrorMessage(error) as string);
      }
    };

    // noinspection JSIgnoredPromiseFromCall
    f();
  }, [getAccessTokenSilently, snackbar]);

  const onChange = (e) => {
    setGroupNames(e.target.value);

    // Split text entered by line, remove whitespaces and filter empty
    const cleanGroupList = e.target.value
      .split("\n")
      .map((e) => e.trim())
      .filter((e) => e !== "");

    const setWhitelist = async () => {
      const accessToken = await getAccessTokenSilently();
      try {
        await axios.put(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
            "/groups/sync/whitelist",
          {
            whitelisted: cleanGroupList,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        snackbar.showMessage(
          cleanGroupList.length + " whitelisted groups(s) saved",
        );
      } catch (error) {
        snackbar.showMessage(extractErrorMessage(error) as string);
      }
    };
    const cleanSerializedList = JSON.stringify(cleanGroupList.sort());
    if (cleanSerializedList !== previousSerializedGroup.current) {
      // noinspection JSIgnoredPromiseFromCall
      setWhitelist();
      // Set the serialized list for later use
      previousSerializedGroup.current = cleanSerializedList;
    }
  };

  return (
    <div>
      <h4>Whitelisted groups (optional)</h4>
      <div>
        Add one name per line below to indicate what groups should be imported
        from your{" "}
        {companyProvider === "GOOGLE" ? "Google Workspace" : "Azure AD"}
        . Leave empty to import all your groups.
        <br />
        <div>
          <TextareaAutosize
            defaultValue={groupNames}
            minRows={3}
            placeholder="group1"
            onChange={(e) => onChange(e)}
            style={{ marginTop: "8px", width: "30%" }}
          />
        </div>
      </div>
    </div>
  );
}
