import React, { useState } from "react";
import styled from "styled-components";
import { Button, LinearProgress } from "@mui/material";
import { UsersBannersListTable } from "@/pages/banners/users/UsersBannersListTable";
import BootstrapStyleSwitch from "@/components/controls/BootstrapStyleSwitch";
import useBannerUsers from "@/pages/banners/remote-apis/bannerUsers";
import useBannerUsersState, {
  useCanEngageUser,
} from "@/pages/banners/users/state";

const UserOptions = styled.div`
  display: flex;
  flex-direction: row-reverse;

  margin-top: 10px;
  margin-bottom: 8px;
`;

const ButtonStyle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export function UsersBannersListContainer() {
  const {
    users: usersRemote,
    usersFetchIsPending,
    fetchUsers,
  } = useBannerUsers();

  const {
    users,
    engageOrDisengage,
    disengageAllCompany,
    deactivateAllActions,
  } = useBannerUsersState(usersRemote, fetchUsers);

  const canEngageUser = useCanEngageUser();

  const [showEngagedUsersOnly, setShowEngagedUsersOnly] = useState(false);
  const displayedUsers = users.filter((user) => {
    if (showEngagedUsersOnly) {
      return (
        user.banners_engagement_status === "engaged" ||
        user.banners_engagement_status === "disengage_started"
      );
    }
    return true;
  });

  const handleActiveUserSwitch = (event) => {
    setShowEngagedUsersOnly(event.target.checked);
  };

  if (usersFetchIsPending && usersRemote === null) {
    return <LinearProgress />;
  }

  return (
    <div>
      <div>
        <UserOptions>
          <BootstrapStyleSwitch
            id="active-users-switch"
            label="Show only users with banner activated"
            checked={showEngagedUsersOnly}
            onChange={handleActiveUserSwitch}
          />
        </UserOptions>
      </div>
      <div className="row">
        <div className="col-md-12 no-right-padding no-left-padding">
          {displayedUsers && (
            <UsersBannersListTable
              users={displayedUsers}
              handleEngageOrDisengageUser={engageOrDisengage}
              deactivateAllUserActions={deactivateAllActions}
              canEngageUser={canEngageUser}
            />
          )}
        </div>
      </div>
      <div className="buttons">
        <div>
          <ButtonStyle>
            <Button
              disabled={deactivateAllActions}
              variant="contained"
              color="warning"
              onClick={() => {
                if (
                  window.confirm(
                    "This action deactivate banners for all users, are you sure ?",
                  )
                ) {
                  disengageAllCompany();
                }
              }}
            >
              Deactivate Banners
            </Button>
          </ButtonStyle>
        </div>
      </div>
    </div>
  );
}
