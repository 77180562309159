import {
  AwarenessSetupChecklist,
  AwarenessStepperStateKey,
  SetupStep,
} from "./SetupChecklistTypes";
import { cloneDeep } from "lodash";

export class SetupStepper {
  readonly steps: SetupStep[];

  constructor(state: AwarenessSetupChecklist) {
    this.steps = cloneDeep(defaultStepperState);
    this.initStepper(state);
  }

  initStepper(state: AwarenessSetupChecklist) {
    const keys = Object.values(AwarenessStepperStateKey).filter(
      (key) => !isNaN(Number(key)),
    );
    keys.forEach((key) => {
      stepsInitializer?.[key]?.(state, this.steps[key]);
    });
  }
}

const defaultStepperState: SetupStep[] = [];
defaultStepperState[AwarenessStepperStateKey.MESSAGING_PLATFORM] = {
  label: "Messaging Platform",
  status: "uncompleted",
  mandatory: true,
};
defaultStepperState[AwarenessStepperStateKey.MESSAGING_PLATFORM_INSTALLED] = {
  label: "Deploy Alex",
  status: "uncompleted",
  mandatory: true,
};
defaultStepperState[AwarenessStepperStateKey.ONE_COURSE_ASSIGNED] = {
  label: "Assign your first course",
  status: "uncompleted",
  mandatory: false,
};
defaultStepperState[AwarenessStepperStateKey.COURSE_DELIVERY_ACTIVATED] = {
  label: "Activate automatic course delivery",
  status: "uncompleted",
  mandatory: false,
};

const stepsInitializer: ((
  checklistSliceState: AwarenessSetupChecklist,
  currentStepState: SetupStep,
) => void)[] = [];
stepsInitializer[AwarenessStepperStateKey.MESSAGING_PLATFORM] = (
  checklistSliceState: AwarenessSetupChecklist,
  currentStepState: SetupStep,
) => {
  if (checklistSliceState.messagingPlatform != null) {
    currentStepState.status = "succeeded";
  } else {
    currentStepState.status = "error";
  }
};

stepsInitializer[AwarenessStepperStateKey.MESSAGING_PLATFORM_INSTALLED] = (
  checklistSliceState: AwarenessSetupChecklist,
  currentStepState: SetupStep,
) => {
  if (checklistSliceState.messagingPlatformInstalled === true) {
    currentStepState.status = "succeeded";
  } else {
    currentStepState.status = "error";
  }
};

stepsInitializer[AwarenessStepperStateKey.ONE_COURSE_ASSIGNED] = (
  checklistSliceState: AwarenessSetupChecklist,
  currentStepState: SetupStep,
) => {
  if (checklistSliceState.oneCourseAssigned === true) {
    currentStepState.status = "succeeded";
  } else {
    currentStepState.status = "error";
  }
};

stepsInitializer[AwarenessStepperStateKey.COURSE_DELIVERY_ACTIVATED] = (
  checklistSliceState: AwarenessSetupChecklist,
  currentStepState: SetupStep,
) => {
  if (checklistSliceState.courseDeliveryActivated === true) {
    currentStepState.status = "succeeded";
  } else {
    currentStepState.status = "error";
  }
};
