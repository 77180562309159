import React from "react";
import { IconButton } from "@mui/material";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import style from "@/pages/threats/Threats.module.css";

type ThreatListFooterProps = {
  offset: number;
  rowPerPages: number;
  threats: Threat[];
  totalThreatsCount: number;
  prevPaginate: () => void;
  nextPaginate: () => void;
};

export const ThreatListFooter = ({
  offset,
  rowPerPages,
  threats,
  totalThreatsCount,
  prevPaginate,
  nextPaginate,
}: ThreatListFooterProps) => {
  return (
    <div className={style.threatListFooter}>
      <div>
        {offset} - {offset + Math.min(rowPerPages, threats.length - 1) + 1} on{" "}
        {totalThreatsCount > 0 ? totalThreatsCount + 1 : 0}
      </div>
      <IconButton size="small" disabled={offset <= 1} onClick={prevPaginate}>
        <ArrowLeft />
      </IconButton>
      <IconButton
        size="small"
        disabled={offset + rowPerPages >= totalThreatsCount}
        onClick={nextPaginate}
      >
        <ArrowRight />
      </IconButton>
    </div>
  );
};
