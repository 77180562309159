import React from "react";
import { SvgIcon } from "@mui/material";

export default function () {
  return (
    <SvgIcon>
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.0792 8.51235C16.0791 8.02825 15.8945 7.54415 15.5251 7.17479C15.1557 6.80542 14.6716 6.62074 14.1875 6.62074M14.1875 14.1874C17.3217 14.1874 19.8625 11.6466 19.8625 8.5124C19.8625 5.37819 17.3217 2.8374 14.1875 2.8374C11.0533 2.8374 8.51249 5.37819 8.51249 8.5124C8.51249 8.77126 8.52983 9.02606 8.56339 9.27573C8.6186 9.68635 8.6462 9.89167 8.62762 10.0216C8.60826 10.1569 8.58362 10.2298 8.51693 10.3491C8.45291 10.4637 8.34008 10.5765 8.11442 10.8021L3.28074 15.6358C3.11716 15.7994 3.03536 15.8812 2.97687 15.9767C2.92501 16.0613 2.8868 16.1535 2.86363 16.25C2.83749 16.3589 2.83749 16.4746 2.83749 16.7059V18.3491C2.83749 18.8788 2.83749 19.1436 2.94058 19.346C3.03126 19.5239 3.17596 19.6686 3.35393 19.7593C3.55625 19.8624 3.82111 19.8624 4.35083 19.8624H6.62083V17.9707H8.51249V16.0791H10.4042L11.8978 14.5855C12.1234 14.3598 12.2362 14.247 12.3508 14.183C12.4701 14.1163 12.543 14.0916 12.6783 14.0723C12.8082 14.0537 13.0135 14.0813 13.4242 14.1365C13.6738 14.1701 13.9286 14.1874 14.1875 14.1874Z"
          stroke="#DDE3EF"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
