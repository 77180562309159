import React, { useState } from "react";
import {
  Button,
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import DownloadingIcon from "@mui/icons-material/Downloading";
import GenericCard from "@/pages/browser-defender/components/GenericCard";
import {
  PaperHeader,
  StyledListWithSmallerIconSpacing,
  StyledPaper,
} from "@/pages/browser-defender/components/StyledElements";

function ControlledAccordion({ enterpriseToken }) {
  const [clipboardCopy, setClipboardCopy] = useState(false);

  function getToken() {
    navigator.clipboard.writeText(enterpriseToken);
    setClipboardCopy(true);
    setTimeout(() => setClipboardCopy(false), 2000);
  }

  return (
    <GenericCard title={"Resources and Support"}>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        useFlexGap
      >
        <StyledPaper>
          <PaperHeader>Enterprise token</PaperHeader>

          <Typography color={"#656E77"} sx={{ mb: "1rem" }}>
            Add this token to your managed deployment configuration for better
            insights into your deployment progress.
          </Typography>
          <Button
            onClick={getToken}
            variant="contained"
            color="primary"
            disabled={enterpriseToken ? false : true}
          >
            {clipboardCopy ? "Copied!" : "Copy to clipboard"}
          </Button>
        </StyledPaper>
        <StyledPaper>
          <PaperHeader>Managed deployments</PaperHeader>
          <StyledListWithSmallerIconSpacing disablePadding="true">
            <ListItemButton>
              <ListItemIcon>
                <GoogleIcon />
              </ListItemIcon>
              <Link
                href="https://www.notion.so/mantrams/Browser-Defender-deployment-bd41b76b09214823b962aeaf3d5487ef"
                target="_blank"
                rel="noopener"
              >
                <ListItemText primary="Google" />
              </Link>
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>
                <MicrosoftIcon />
              </ListItemIcon>
              <Link
                href="https://www.notion.so/mantrams/Browser-Defender-7f2688e655184c39995b91cb2cbca679"
                target="_blank"
                rel="noopener"
              >
                <ListItemText primary="Microsoft" />
              </Link>
            </ListItemButton>
          </StyledListWithSmallerIconSpacing>
        </StyledPaper>
        <StyledPaper>
          <PaperHeader>Internal communication examples</PaperHeader>

          <StyledListWithSmallerIconSpacing disablePadding="true">
            <ListItemButton>
              <ListItemIcon>
                <AutoModeIcon />
              </ListItemIcon>
              <Link
                href="https://www.notion.so/mantrams/Automatic-setup-user-signed-in-on-browser-6868d9da31f44ffaa6bb7ce81631a4e4"
                target="_blank"
                rel="noopener"
              >
                <ListItemText primary="Automatic setup" />
              </Link>
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>
                <DownloadingIcon />
              </ListItemIcon>
              <Link
                href="https://www.notion.so/mantrams/Manual-setup-user-not-signed-in-on-browser-6db5eac72ed34a1bb91dc322f00d7752"
                target="_blank"
                rel="noopener"
              >
                <ListItemText primary="Manual setup" />
              </Link>
            </ListItemButton>
          </StyledListWithSmallerIconSpacing>
        </StyledPaper>
      </Stack>
    </GenericCard>
  );
}

export function Documentation({ enterpriseToken }) {
  return <ControlledAccordion enterpriseToken={enterpriseToken} />;
}
