import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import GenericCard from "@/pages/browser-defender/components/GenericCard";
import { CenteredStyledSpinner } from "@/components/Spinner";
import CustomDialog from "@/pages/browser-defender/components/CustomDialog";
import CompanyConfigurationForm from "@/pages/browser-defender/setup/CompanyConfigurationForm";
import EditIcon from "@mui/icons-material/Edit";

export function CompanySettings({
  companySettings,
  saveCompanySettings,
  statusColors,
}) {
  return (
    <GenericCard title={"Company level settings"}>
      {companySettings.loading && (
        <CenteredStyledSpinner width="50px" color="#777" />
      )}
      {!companySettings.loading && (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center">Password Protection</TableCell>
                <TableCell align="center">Downloads Protection</TableCell>
                <TableCell align="center">Browsing Protection</TableCell>
                <TableCell align="center">User can disable extension</TableCell>
                <TableCell align="center">Authorized login domain(s)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companySettings.data && (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell>
                    <CustomDialog
                      callToAction={<EditIcon />}
                      callToActionText="Edit company settings"
                      actionButtonText="Save configuration"
                      actionButtonCallback={saveCompanySettings}
                      dialogTitle="Company level settings"
                    >
                      <CompanyConfigurationForm record={companySettings.data} />
                    </CustomDialog>
                  </TableCell>
                  <TableCell align="center">
                    {statusColors[companySettings.data.password_protection]}
                  </TableCell>
                  <TableCell align="center">
                    {statusColors[companySettings.data.downloads_protection]}
                  </TableCell>
                  <TableCell align="center">
                    {statusColors[companySettings.data.browsing_protection]}
                  </TableCell>
                  <TableCell align="center">
                    {companySettings.data.user_can_disable_extension
                      ? statusColors["on"]
                      : statusColors["off"]}
                  </TableCell>
                  <TableCell align="center">
                    {companySettings.data.safe_domains &&
                      companySettings.data.safe_domains.join(", ")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </GenericCard>
  );
}
