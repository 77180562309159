import { extractErrorMessage, getConfig, useBackend } from "@/utils/misc";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";
import { useState } from "react";
import { BACKEND_URL } from "@/pages/banners/remote-apis/config";

export interface APIBannerUser {
  firstname: string;
  lastname: string;
  email: string;
  departments: Array<string>;
  language: string;
  banners_engagement_status:
    | "engaged"
    | "engage_started"
    | "disengaged"
    | "disengage_started";
}

export interface BannerToggleUsers {
  user_email: string;
  active: boolean;
}

export default function useBannerUsers(activeUsersOnly = false) {
  const url = new URL(`${BACKEND_URL}/banners/users`);
  url.searchParams.append("active_only", activeUsersOnly.toString());

  const response = useBackend(url.href);
  const data: Array<APIBannerUser> | null = response.data;

  return {
    users: data,
    usersFetchingError: response.error,
    usersFetchIsPending: response.isPending,
    fetchUsers: response.fetchData,
  };
}

export function useToggleUsers(users: Array<BannerToggleUsers>) {
  const url = new URL(`${BACKEND_URL}/user/gmail_controller/toggle_users`);

  const { error, fetchData } = useBackend(url.href, "POST", users);
  return {
    toggleUsersError: error,
    fetch: fetchData,
  };
}

export function useRemoteEngageOrDisengageUser(): {
  engageOrDisengageCall: (userEmail: string, engage: boolean) => Promise<void>;
} {
  const { getAccessTokenSilently } = useAuth0();
  const snackbar = useSnackbar();

  async function engageOrDisengageCall(userEmail: string, engage: boolean) {
    try {
      await axios.post(
        `${BACKEND_URL}/user/gmail_controller`,
        { user_email: userEmail, active: engage },
        await getConfig(getAccessTokenSilently),
      );
    } catch (error) {
      snackbar.showMessage(extractErrorMessage(error) || "An error occurred.");
      return;
    }
  }

  return { engageOrDisengageCall };
}

export function useRemoteDisengageCompany(): {
  disengageCompanyCall: () => Promise<void>;
  isLoading: boolean;
} {
  const { getAccessTokenSilently } = useAuth0();
  const snackbar = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  async function disengageCompanyCall() {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const result = await axios.post(
        `${BACKEND_URL}/company/disengage`,
        {},
        await getConfig(getAccessTokenSilently),
      );
      if (result.status === 207) {
        snackbar.showMessage(result.data);
      }
    } catch (error) {
      snackbar.showMessage(extractErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  }

  return { disengageCompanyCall, isLoading };
}
