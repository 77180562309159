import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Alert, Divider, Snackbar, Typography } from "@mui/material";
import { useState } from "react";
import { useToggleUsers } from "../remote-apis/bannerUsers";
import { useSnackbar } from "material-ui-snackbar-provider";

const MAXIMUM_ENGAGEABLE_USERS = 100;

export default function ModalEngageAdditionalUsers({
  confirmationDialogOpen,
  setConfirmationDialogOpen,
  users,
}) {
  const [usersToEngage, setUsersToEngage] = useState([]);
  const userDisengaged = users.filter(
    (user) => user.banners_engagement_status === "disengaged",
  );
  const { toggleUsersError, fetch } = useToggleUsers(usersToEngage);
  const snackbar = useSnackbar();

  const numberOfUserToEngage = Math.min(
    userDisengaged.length,
    MAXIMUM_ENGAGEABLE_USERS,
  );

  const HandleEngage = (event) => {
    event.preventDefault();
    setUsersToEngage(
      userDisengaged
        .map((v) => ({ user_email: v.email, active: true }))
        .slice(0, MAXIMUM_ENGAGEABLE_USERS),
    );
    fetch();
    if (toggleUsersError) {
      snackbar.showMessage("Unable to engage users");
    }
    handleClose();
  };

  const handleClose = () => {
    setConfirmationDialogOpen(false);
  };

  if (numberOfUserToEngage === 0) {
    return (
      <>
        <Snackbar
          onClose={handleClose}
          open={confirmationDialogOpen}
          autoHideDuration={7000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            All users are already engaged
          </Alert>
        </Snackbar>
      </>
    );
  } else {
    return (
      <>
        <Dialog
          open={confirmationDialogOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Engage additional users
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Typography variant="h6" align="center">
              You're about to engage {numberOfUserToEngage} additional users{" "}
            </Typography>

            <DialogContentText id="alert-dialog-description">
              By clicking "Confirm", you will randomly activate the Smart
              Banners for {numberOfUserToEngage} users.
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions style={{ justifyContent: "space-between" }}>
            <Button
              onClick={handleClose}
              variant="contained"
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
            <Button variant="outlined" color="warning" onClick={HandleEngage}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
