import { useSimulationResource } from "@/utils/ResourceGet";
import React, { useEffect, useState } from "react";
import { getDomain, getEmailJwt } from "@/utils/misc";
import { useAuth0 } from "@auth0/auth0-react";
import {
  APIBannerUser,
  useRemoteDisengageCompany,
  useRemoteEngageOrDisengageUser,
} from "@/pages/banners/remote-apis/bannerUsers";

export function useCanEngageUser(): boolean {
  const { getAccessTokenSilently } = useAuth0();
  const [company] = useSimulationResource("company", null);
  const [canEngageUser, setCanEngageUser] = useState(false);

  useEffect(() => {
    if (company?.admin_can_engage_users_to_banners === true) {
      setCanEngageUser(true);
    } else {
      const retrieveRealUserEmail = async () => {
        const accessToken = await getAccessTokenSilently();
        const jwtEmail = getEmailJwt(accessToken);
        const domain = getDomain(jwtEmail);
        setCanEngageUser(domain === "mantra.ms");
      };
      // noinspection JSIgnoredPromiseFromCall
      retrieveRealUserEmail();
    }
  }, [company, setCanEngageUser, getAccessTokenSilently]);

  return canEngageUser;
}

export type EngagementStatus =
  | "engaged"
  | "engage_started"
  | "disengaged"
  | "disengage_started";

export interface BannerUser {
  firstname: string;
  lastname: string;
  email: string;
  departments: Array<string>;
  language: string;
  banners_engagement_status: EngagementStatus;
}

export default function useBannerUsersState(
  usersRemote: Array<APIBannerUser> | null,
  fetchUsers: () => void,
): {
  users: Array<BannerUser>;
  engageOrDisengage: (userEmail: string, engage: boolean) => Promise<void>;
  disengageAllCompany: () => Promise<void>;
  deactivateAllActions: boolean;
} {
  const [usersLocal, setUsersLocal] = useLocalUsers(usersRemote, fetchUsers);

  const { engageOrDisengageCall } = useRemoteEngageOrDisengageUser();
  const engageOrDisengage = async (userEmail: string, engage: boolean) => {
    await engageOrDisengageCall(userEmail, engage);

    const newUsers = [...usersLocal];
    newUsers.find(
      (user) => user.email === userEmail,
    ).banners_engagement_status = engage
      ? "engage_started"
      : "disengage_started";
    setUsersLocal(newUsers);
  };

  const { disengageCompanyCall, isLoading: isDisengageCompanyLoading } =
    useRemoteDisengageCompany();
  const disengageAllCompany = async () => {
    await disengageCompanyCall();

    const newUsers = [...usersLocal];
    newUsers
      .filter((user) => user.banners_engagement_status === "engaged")
      .forEach(
        (user) => (user.banners_engagement_status = "disengage_started"),
      );

    setUsersLocal(newUsers);
  };

  return {
    users: usersLocal,
    engageOrDisengage,
    disengageAllCompany,
    deactivateAllActions: isDisengageCompanyLoading,
  };
}

function useLocalUsers(
  usersRemote: Array<APIBannerUser> | null,
  fetchUsers: () => void,
): [Array<BannerUser>, React.Dispatch<Array<BannerUser>>] {
  const [usersLocal, setUsersLocal] = useState<Array<BannerUser>>([]);
  useEffect(() => {
    if (usersRemote === null) {
      return;
    }

    setUsersLocal(usersRemote);
  }, [usersRemote]);

  useEffect(() => {
    /** We want to keep polling if some users are in a transitional state. */

    if (
      !usersLocal.some(
        (user) =>
          user.banners_engagement_status === "engage_started" ||
          user.banners_engagement_status === "disengage_started",
      )
    ) {
      return;
    }

    const timeout = setTimeout(fetchUsers, 3000);
    return () => clearTimeout(timeout);
  }, [usersLocal, fetchUsers]);

  return [usersLocal, setUsersLocal];
}
