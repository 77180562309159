import React, { MouseEvent, ReactElement } from "react";
import Box from "@mui/material/Box";
import { Checkbox, TextField, styled } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import TableSort from "./TableSort";

const StyledTableHeaderRow = styled(TableRow)(({ theme }) => ({
  "& .MuiTableCell-head": {
    fontSize: "1rem",
    fontWeight: "600",
    color: "#000000",
  },
}));

type RawColumnSort = {
  column: string;
  direction: -1 | 1;
};

export type PaginationProps = {
  itemsPerPage: number;
  setItemsPerPage: (itemsPerPage: number) => void;
  moveToNextPage: () => void;
  moveToPreviousPage: () => void;
  totalItemCount: number;
  currentPage: number;
};

export type TableHeader = {
  label: string;
  alignment: "center" | "left" | "right";
};
export type SortingColumn = { label: string; name: string };

type CollapsibleTableProps = {
  headers: TableHeader[];
  rows: ReactElement[];
  fetchOptions: ListFetchOptions;
  setFetchOptions: React.Dispatch<React.SetStateAction<ListFetchOptions>>;
  paginationProps: PaginationProps;
  selectAll: (e: MouseEvent) => void;
  areAllSelected: boolean;
  sortingColumns: SortingColumn[];
  filterButtons: ReactElement;
};

const CollapsibleTable = ({
  headers,
  rows,
  fetchOptions,
  setFetchOptions,
  selectAll,
  areAllSelected,
  paginationProps,
  sortingColumns,
  filterButtons,
}: CollapsibleTableProps) => {
  const onSearch = (e) => {
    if (e.keyCode !== 13) {
      return;
    }
    const value = e.target.value;
    setFetchOptions((prev) => ({ ...prev, searchTerm: value }));
  };

  const handleSortChange = (rawSort: RawColumnSort[]) => {
    const sort = rawSort.map((s) => ({
      column: s.column,
      direction: s.direction === 1 ? ("asc" as const) : ("desc" as const),
    }));
    setFetchOptions((prev) => ({ ...prev, sort: sort }));
  };

  return (
    <Box>
      <TextField
        InputProps={{
          startAdornment: <SearchIcon />,
        }}
        sx={{ marginBottom: "10px" }}
        size={"small"}
        label={"Search"}
        onKeyDown={onSearch}
      />
      {
        <TableSort
          setOrderBy={handleSortChange}
          columns={sortingColumns}
          defaultOrder={[{ column: sortingColumns[0].name, direction: 1 }]}
        />
      }
      {filterButtons}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
          {headers && (
            <TableHead>
              <StyledTableHeaderRow>
                <TableCell>
                  <Checkbox
                    checked={areAllSelected}
                    onClick={(e) => selectAll(e)}
                  />
                </TableCell>
                {headers.map((header) => (
                  <TableCell align={header.alignment} key={header.label}>
                    {header.label}
                  </TableCell>
                ))}
              </StyledTableHeaderRow>
            </TableHead>
          )}
          <TableBody>
            {rows.length > 0 ? (
              rows
            ) : (
              <TableRow>
                <TableCell>No data available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={paginationProps.totalItemCount}
        rowsPerPage={paginationProps.itemsPerPage}
        page={paginationProps.currentPage}
        onPageChange={(_, newPageIndex) => {
          if (newPageIndex > paginationProps.currentPage) {
            paginationProps.moveToNextPage();
          } else if (newPageIndex < paginationProps.currentPage) {
            paginationProps.moveToPreviousPage();
          }
        }}
        onRowsPerPageChange={(event) => {
          paginationProps.setItemsPerPage(parseInt(event.target.value, 10));
        }}
      />
    </Box>
  );
};

export default CollapsibleTable;
