import { Autocomplete, Button, LinearProgress, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { WhitelistingScenarios } from "@/pages/phishing-simulation/setup/WhitelistingScenarios";
import { WhitelistingTestLog } from "@/pages/phishing-simulation/setup/WhitelistingTestLog";
import Divider from "@mui/material/Divider";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { extractErrorMessage } from "@/utils/misc";
import { Portlet, PortletBody } from "@/components/content/Portlet";

const TEMPLATE_KEY = "template";
const MANTRA_DOMAINS = [
  "mantra.ms",
  "getplexx.com",
  "mantrams.onmicrosoft.com",
  "getplexx.onmicrosoft.com",
];

export function DeliveryTest() {
  const [templateList, setTemplateList] = useState([]);
  const [template, setTemplate] = useState(null);
  const [outboundRequest, setOutboundRequest] = useState(false);
  const [lastSimuSent, setLastSimuSent] = useState(null);
  const [errorMessageSimu, setErrorMessageSimu] = useState("");

  const { user, getAccessTokenSilently } = useAuth0();

  let sendSimulation = () => null;

  let isStaff =
    MANTRA_DOMAINS.filter((d) => user.email.endsWith(`@${d}`)).length > 0;
  useEffect(() => {
    if (isStaff) {
      const f = async () => {
        const accessToken = await getAccessTokenSilently();
        const endpointPath = `${
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION
        }/templates/name`;
        const result = await axios.get(endpointPath, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        setTemplateList(result.data.templates);
        try {
          setTemplate(JSON.parse(localStorage.getItem(TEMPLATE_KEY)));
        } catch (e) {}
      };
      // noinspection JSIgnoredPromiseFromCall
      f();
    }
  }, [getAccessTokenSilently, isStaff]);

  if (isStaff) {
    sendSimulation = async () => {
      const accessToken = await getAccessTokenSilently();
      try {
        if (!template) {
          setErrorMessageSimu("Please select a template");
          return;
        }
        localStorage.setItem(TEMPLATE_KEY, JSON.stringify(template));
        setOutboundRequest(true);
        const response = await axios.post(
          `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/email_to_user`,
          {
            toUser: user.email,
            template: template.name,
            template_language: template.language,
            prod_smtp: true,
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          },
        );
        setLastSimuSent(response.data.id);
        setOutboundRequest(false);
        setErrorMessageSimu(null);
      } catch (error) {
        setLastSimuSent(false);
        setOutboundRequest(false);
        setErrorMessageSimu(extractErrorMessage(error));
      }
    };
  }
  return (
    <Portlet>
      <PortletBody>
        <div>
          <div>
            <WhitelistingScenarios />

            <WhitelistingTestLog />
          </div>
        </div>
        {templateList.length > 0 && (
          <div>
            <Divider sx={{ p: 2 }} />
            <div className="row">
              <div className="col-md-7">
                <Autocomplete
                  options={templateList}
                  value={template}
                  getOptionLabel={(option) =>
                    `${option.name} | ${option.language}`
                  }
                  isOptionEqualToValue={(option) =>
                    templateList.length &&
                    templateList.findIndex((o) => o === option) >= 0
                  }
                  className="mt-3"
                  onChange={(e, value) => setTemplate(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Template" />
                  )}
                />
              </div>
              <div className="col-md-3">
                <Button
                  variant="contained"
                  color="yellow"
                  className="mt-4"
                  disabled={outboundRequest}
                  onClick={sendSimulation}
                >
                  Send a&nbsp;
                  <span role="img" aria-label="phishing">
                    &#127907;
                  </span>
                  &nbsp; simulation
                </Button>
              </div>
            </div>
            <div className="row">
              {lastSimuSent && template && (
                <div style={{ color: "green" }} className="col mt-1">
                  <strong>
                    {template.name} | {template.language}{" "}
                  </strong>
                  sent to <strong>{user.email}</strong>, simulation id=
                  <strong>{lastSimuSent}</strong>
                </div>
              )}
              {errorMessageSimu && (
                <div style={{ color: "red" }} className="col mt-1">
                  {errorMessageSimu}
                </div>
              )}
            </div>
          </div>
        )}
        {outboundRequest && (
          <div className="row">
            <div className="col">
              <div className="col-10" id="linear-progress">
                <LinearProgress className="mt-2" />
              </div>
            </div>
          </div>
        )}
      </PortletBody>
    </Portlet>
  );
}
