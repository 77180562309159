import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CollapsibleTable from "@/pages/browser-defender/components/CollapsibleTable";
import TableCell from "@mui/material/TableCell";
import StyledTableRow from "@/pages/browser-defender/styles/StyledTableRow";
import ActionChip from "@/pages/browser-defender/components/ActionChip";
import RiskChip from "@/pages/browser-defender/components/RiskChip.jsx";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

const headers = [
  { label: "Time (UTC)", alignment: "left" },
  { label: "Email", alignment: "left" },
  { label: "Domain", alignment: "left" },
  { label: "File name", alignment: "left" },
  { label: "File size", alignment: "left" },
  { label: "Risk level", alignment: "center" },
  { label: "Browser", alignment: "center" },
  { label: "User action", alignment: "center" },
];

const sortingColumns = [
  { label: "Email", name: "email" },
  { label: "Domain", name: "domain" },
  { label: "File name", name: "file_name" },
  { label: "Lastname", name: "lastname" },
  { label: "User action", name: "user_action" },
  { label: "Browser", name: "browser" },
  { label: "Risk level", name: "extension_danger_level" },
];

const filterColumns = [
  "file_name",
  "email",
  "domain",
  "user_action",
  "browser",
  "extension_danger_level",
];

const riskColors = {
  high: {
    bgColor: "#FEE9EF8C",
    textColor: "#F6285F",
    icon: <WarningAmberIcon color={"#FEE9EF"} />,
  },
  medium: {
    bgColor: "#FEF5ED8C",
    textColor: "#F2994A",
    icon: <ErrorOutlineOutlinedIcon color={"#FEF5ED"} />,
  },
  default: {
    bgColor: "#65c58b8C",
    textColor: "#65C58B",
    icon: <CheckCircleOutlineOutlinedIcon color={"#65c58b"} />,
  },
};

const actionColors = {
  cancel: { bgColor: "rgba(255, 175, 44, 0.25)", textColor: "#BE7A0C" },
  download: { bgColor: "rgba(255, 175, 44, 0.5)", textColor: "#BE7A0C" },
  default: { bgColor: "rgba(255, 175, 44, 0.25)", textColor: "#B50A3E" },
};

const actionMapping = {
  cancel: "Cancelled",
  download: "Downloaded",
};

export default function DataTable({ data, setData }) {
  const [sorting, setSorting] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    let filteredValues = data.values;

    if (searchTerm) {
      filteredValues = data.values.filter(function (row) {
        return Object.keys(row).some(function (attribute) {
          if (!filterColumns.includes(attribute)) {
            return false;
          }

          if (!row[attribute]) {
            return false;
          }

          let value;

          try {
            value = row[attribute].toLowerCase();
          } catch {
            value = row[attribute];
          }

          return value.indexOf(searchTerm) > -1;
        });
      });
    }

    function parseDates(stringDate) {
      let [dateComp, timeComp] = stringDate.split(" ");
      let [day, month, year] = dateComp.split("-");
      let [hours, minutes] = timeComp.split(":");
      return new Date(+year, +month - 1, +day, +hours, +minutes);
    }

    if (sorting) {
      filteredValues.sort((p1, p2) => {
        let condition = 1;
        for (let sortRule of sorting) {
          if (sortRule.column === "event_date") {
            const parsedDate1 = parseDates(p1[sortRule.column]);
            const parsedDate2 = parseDates(p2[sortRule.column]);

            condition *=
              parsedDate1 < parsedDate2
                ? 1 * sortRule.direction
                : parsedDate1 > parsedDate2
                  ? -1 * sortRule.direction
                  : 0;
            continue;
          }
          condition *=
            p1[sortRule.column] < p2[sortRule.column]
              ? -1 * sortRule.direction
              : p1[sortRule.column] > p2[sortRule.column]
                ? 1 * sortRule.direction
                : 0;
        }
        return condition;
      });
    }

    setData({
      values: data.values,
      filteredValues: filteredValues,
      loading: false,
    });
  }, [searchTerm, sorting, data.values, setData]);

  return (
    <CollapsibleTable
      headers={headers}
      sortingColumns={sortingColumns}
      setSorting={setSorting}
      setSearchTerm={setSearchTerm}
      defaultOrder={[{ column: "event_date", direction: 1 }]}
      rows={data.filteredValues.map((row, index) => {
        return (
          <StyledTableRow
            key={index}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row" align={headers[0].alignment}>
              {row.event_date}
            </TableCell>
            <TableCell align={headers[1].alignment}>
              <Link>{row.email}</Link>
            </TableCell>
            <TableCell align={headers[1].alignment}>{row.domain}</TableCell>
            <TableCell align={headers[2].alignment}>{row.file_name}</TableCell>
            <TableCell align={headers[3].alignment}>{row.file_size}</TableCell>
            <TableCell align={headers[4].alignment}>
              <RiskChip
                danger_level={row.extension_danger_level}
                colors={riskColors}
              />
            </TableCell>
            <TableCell align={headers[5].alignment}>{row.browser}</TableCell>
            <TableCell align={headers[6].alignment}>
              <ActionChip
                data={row}
                actionMapping={actionMapping}
                colors={actionColors}
              />
            </TableCell>
          </StyledTableRow>
        );
      })}
    />
  );
}
