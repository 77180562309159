import React from "react";
import { ServiceAccount } from "@/pages/general-settings/GSuite/ServiceAccount";
import { ManualSync } from "@/pages/general-settings/commonUserProvisioning/ManualSync";
import { EnableCheckbox } from "@/pages/general-settings/commonUserProvisioning/EnableCheckbox";
import { Typography } from "@mui/material";
import { Blacklist } from "@/pages/general-settings/commonUserProvisioning/Blacklist";
import { Portlet, PortletBody } from "@/components/content/Portlet";
import { useUserContext } from "@/utils/contexts/UserContext";
import { GroupWhitelist } from "@/pages/general-settings/GroupWhitelist";

export function GSuiteSync() {
  const user = useUserContext();

  return (
    <Portlet>
      <PortletBody>
        {user?.base_company.id === user?.current_company.id ? (
          <Typography component={"span"} style={{ color: "black" }}>
            <div>
              Please follow{" "}
              <a
                href="https://www.notion.so/mantrams/User-Provisioning-4e417c72f3074344b1a90c1fc991551e"
                target="_blank"
                rel="noopener noreferrer"
              >
                this procedure
              </a>{" "}
              to configure your Google Workspace account.
            </div>
            <br />
            <h4>Service account status</h4>
            <ServiceAccount />
            <br />
            <h4>Blacklisted users (optional)</h4>
            <Blacklist />
            <br />
            <GroupWhitelist />
            <br />
            <EnableCheckbox />
            <ManualSync />
          </Typography>
        ) : (
          <Typography color="error">
            You can only modify settings from {user?.base_company.name}
          </Typography>
        )}
      </PortletBody>
    </Portlet>
  );
}
