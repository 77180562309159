import { CenteredBigSpinner } from "@/components/Spinner";
import Stepper from "@/components/stepper/Stepper";
import {
  selectStepperFromChecklistState,
  updateChecklistState,
  updateMessagingPlatformInstalledStatus,
} from "@/pages/awareness/setup/Checklist/SetupChecklistSlice";
import { Box, Button, Paper, Stack } from "@mui/material";
import axios, { AxiosResponse } from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SetupCurrentComponent from "./SetupCurrentComponent";
import { MessagingPlatform } from "./SetupChecklistTypes";

const NO_CALL_TO_SIMULATION = "NO_CALL_TO_SIMULATION";

const useAwarenessChecklist = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const dispatch = useDispatch();
  const steps = useSelector(selectStepperFromChecklistState);
  const prev = () => setActiveIndex((prev) => prev - 1);
  const next = () => setActiveIndex((prev) => prev + 1);
  const onClickStepper = (_: any, index: number) => {
    setActiveIndex(index);
  };
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const activeStep = useMemo(() => {
    return steps[activeIndex];
  }, [activeIndex, steps]);

  // TODO when we will have an interactive setup checklist / wizard,
  // we may want to move this logic in a Redux/rtk-query slice
  // (and do the rest of the logic also with rtk-query)
  useEffect(
    function fetchSetupStatus() {
      axios
        .get(
          `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/company/setup_status`,
        )
        .then((response) => {
          dispatch(updateChecklistState(response.data));
          if (response.data.messaging_platform === MessagingPlatform.WEBCHAT) {
            return axios.get(
              `${
                import.meta.env.VITE_APP_ENDPOINT_SIMULATION
              }/company/setup-status`,
            );
          }
          // In case we don't use
          // webchat, we should not make
          // a call to simulaiton
          return new Promise((resolve) =>
            resolve({
              data: NO_CALL_TO_SIMULATION,
            }),
          );
        })
        .then((response: AxiosResponse) => {
          if (response.data === NO_CALL_TO_SIMULATION) {
            setIsLoading(false);
            return;
          }
          dispatch(
            updateMessagingPlatformInstalledStatus(
              response.data.has_mail_permissions,
            ),
          );
        })
        .catch(() => {
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [dispatch, setIsLoading, setIsError],
  );

  useEffect(
    function updateActiveStepBasedOnStepsStatus() {
      let updatedIndex = -1;
      if (isLoading === true) {
        return;
      }
      for (let i = 0; i < steps.length; i++) {
        if (steps[i].status !== "succeeded") {
          updatedIndex = i;
          break;
        }
      }
      if (updatedIndex === -1) {
        updatedIndex = steps.length - 1;
      }
      setActiveIndex(updatedIndex);
    },
    [steps, isLoading, setActiveIndex],
  );

  return {
    activeIndex,
    activeStep,
    onClickStepper,
    prev,
    next,
    steps,
    isLoading,
    isError,
  };
};

const SetupChecklist: React.FC = () => {
  const {
    activeIndex,
    onClickStepper,
    prev,
    next,
    steps,
    activeStep,
    isLoading,
    isError,
  } = useAwarenessChecklist();

  return (
    <>
      {isLoading && !isError ? (
        <Box marginTop="5rem">
          <CenteredBigSpinner />
        </Box>
      ) : (
        <Stack
          display="grid"
          gap="1rem"
          gridTemplateColumns="2fr 3fr"
          alignItems="start"
        >
          <Paper sx={{ px: "2.5rem", py: "4rem" }}>
            <Stepper
              steps={steps}
              activeIndex={activeIndex}
              onClick={onClickStepper}
            />
          </Paper>
          <Paper sx={{ p: "2.5rem" }}>
            <SetupCurrentComponent activeStep={activeIndex} />
            <Box marginTop="3rem" display="flex" justifyContent="space-between">
              <Button
                variant="outlined"
                disabled={activeIndex === 0}
                onClick={prev}
              >
                Previous
              </Button>
              {activeIndex < steps.length - 1 ? (
                <Button variant="outlined" onClick={next}>
                  {activeStep.mandatory ? "Next" : "Skip"}
                </Button>
              ) : null}
            </Box>
          </Paper>
        </Stack>
      )}
      {isError ? "an error occured please try again later" : null}
    </>
  );
};

export default SetupChecklist;
