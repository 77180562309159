import React, { useEffect, useState } from "react";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { extractErrorMessage } from "@/utils/misc";
import { useAuth0 } from "@auth0/auth0-react";
import { Checkbox, TextField, Typography, Autocomplete } from "@mui/material";
import { useSnackbar } from "material-ui-snackbar-provider";
import axios from "axios";

export function CompanySaasSelector() {
  const snackbar = useSnackbar();
  const { getAccessTokenSilently } = useAuth0();
  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" />;
  const [saasList, setSaasList] = useState([]);
  const [companySaasList, setCompanySaasList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  // Populate the global saas list
  useEffect(() => {
    const f = async () => {
      const accessToken = await getAccessTokenSilently();

      try {
        const result = await axios.get(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/saas",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        setSaasList(result.data);
        setErrorMessage(null);
      } catch (error) {
        setErrorMessage(extractErrorMessage(error));
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    f();
  }, [getAccessTokenSilently]);

  // Populate the company saas list
  useEffect(() => {
    const f = async () => {
      const accessToken = await getAccessTokenSilently();

      try {
        const result = await axios.get(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/company/saas",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        setCompanySaasList(result.data);
        setErrorMessage(null);
      } catch (error) {
        setErrorMessage(extractErrorMessage(error));
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    f();
  }, [getAccessTokenSilently]);

  // Update company saas
  const onChange = (newSaasList) => {
    const f = async () => {
      const accessToken = await getAccessTokenSilently();

      try {
        const result = await axios.put(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/company/saas",
          newSaasList,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        setCompanySaasList(result.data);
        setErrorMessage(null);
        snackbar.showMessage("Company saas updated");
      } catch (error) {
        setErrorMessage(extractErrorMessage(error));
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    f();
  };

  return (
    <div>
      {errorMessage !== null && (
        <Typography style={{ color: "red" }}>{errorMessage}</Typography>
      )}
      <Autocomplete
        multiple
        id="global_company_saas"
        options={saasList}
        disableCloseOnSelect
        onChange={(event, newSaasList) => {
          onChange(newSaasList); // Update company saas
        }}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={companySaasList}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </li>
        )}
        style={{ width: 800 }}
        renderInput={(params) => (
          <TextField {...params} label="" placeholder="" />
        )}
      />
    </div>
  );
}
