import React from "react";
import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import GenericCard from "@/pages/browser-defender/components/GenericCard";
import { CenteredStyledSpinner } from "@/components/Spinner";
import CustomDialog from "@/pages/browser-defender/components/CustomDialog";
import CompanyConfigurationForm from "@/pages/browser-defender/setup/CompanyConfigurationForm";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export function GroupsSettings({
  groupSettings,
  addGroupSettings,
  saveGroupSettings,
  deleteGroupSettings,
  statusColors,
}) {
  return (
    <GenericCard title={"Group level settings"}>
      {groupSettings.loading && (
        <CenteredStyledSpinner width="50px" color="#777" />
      )}
      <Box style={{ display: "flex", flexDirection: "row-reverse" }}>
        <CustomDialog
          callToActionText="Add group settings"
          actionButtonText="Save configuration"
          actionButtonCallback={(formData) => addGroupSettings(formData)}
          dialogTitle="Add group settings"
        >
          <CompanyConfigurationForm
            formType="group"
            groups={groupSettings.groups}
          />
        </CustomDialog>
      </Box>
      {!groupSettings.loading && (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="left">Group Name</TableCell>
                <TableCell align="center">Password Protection</TableCell>
                <TableCell align="center">Downloads Protection</TableCell>
                <TableCell align="center">Browsing Protection</TableCell>
                <TableCell align="center">User can disable extension</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupSettings.data &&
                groupSettings.data.map((setting, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell>
                      <CustomDialog
                        callToAction={<EditIcon />}
                        callToActionText="Edit group settings"
                        actionButtonText="Save configuration"
                        actionButtonCallback={(formData) =>
                          saveGroupSettings(
                            setting.group_id,
                            setting.group_name,
                            formData,
                          )
                        }
                        dialogTitle={`Group settings - ${setting.group_name}`}
                      >
                        <CompanyConfigurationForm
                          record={setting}
                          formType="group"
                        />
                      </CustomDialog>
                      <Tooltip
                        style={{
                          display: "inline-block",
                          paddingLeft: 0,
                        }}
                        title="Delete group settings"
                      >
                        <DeleteIcon
                          style={{ color: "#fd397a", cursor: "pointer" }}
                          onClick={() => deleteGroupSettings(setting.group_id)}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell align="left">{setting.group_name}</TableCell>
                    <TableCell align="center">
                      {statusColors[setting.password_protection]}
                    </TableCell>
                    <TableCell align="center">
                      {statusColors[setting.downloads_protection]}
                    </TableCell>
                    <TableCell align="center">
                      {statusColors[setting.browsing_protection]}
                    </TableCell>
                    <TableCell align="center">
                      {setting.user_can_disable_extension
                        ? statusColors["on"]
                        : statusColors["off"]}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </GenericCard>
  );
}
