import React, { PropsWithChildren } from "react";
import { AwarenessStepperStateKey } from "./SetupChecklistTypes";
import MessagingPlatformInstalled from "./stepComponents/MessagingPlatformInstalled";
import SelectedMessagingPlatform from "./stepComponents/SelectedMessagingPlatform";
import AssignCourse from "./stepComponents/AssignCourse";
import CourseDeliveryActivated from "./stepComponents/CourseDeliveryActivated";

type SetupCurrentComponentProps = PropsWithChildren & {
  activeStep: AwarenessStepperStateKey;
};

const SetupCurrentComponent = ({ activeStep }: SetupCurrentComponentProps) => {
  switch (activeStep) {
    case AwarenessStepperStateKey.MESSAGING_PLATFORM:
      return <SelectedMessagingPlatform />;
    case AwarenessStepperStateKey.MESSAGING_PLATFORM_INSTALLED:
      return <MessagingPlatformInstalled />;
    case AwarenessStepperStateKey.ONE_COURSE_ASSIGNED:
      return <AssignCourse />;
    case AwarenessStepperStateKey.COURSE_DELIVERY_ACTIVATED:
      return <CourseDeliveryActivated />;
  }
};

export default SetupCurrentComponent;
