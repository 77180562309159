import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "material-ui-snackbar-provider";
import { BACKEND_URL } from "@/pages/banners/remote-apis/config";
import { useBackend } from "@/utils/misc";
import axios from "axios";

export function useTemplateNames() {
  const url = new URL(`${BACKEND_URL}/banners/get_email_template_names`);

  const { error, data, isPending } = useBackend(url.href, "GET");
  return {
    templates: data?.names,
    error,
    isPending,
  };
}

export function useSendEmail() {
  const [isSendError, setIsSendError] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const snackbar = useSnackbar();

  const sendEmails = async ({ templateNames, userEmails }) => {
    const accessToken = await getAccessTokenSilently();
    try {
      setIsSending(true);
      const url = new URL(`${BACKEND_URL}/banners/users/send_template_emails`);
      await axios.post(
        url.href,
        { templates: templateNames, email_addresses: userEmails },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );
      setIsSendError(false);
      setIsSending(false);
      snackbar.showMessage("The emails have been sent.");
    } catch (error) {
      setIsSendError(true);
      setIsSending(false);
      snackbar.showMessage("The emails couldn't be sent.");
    }
  };

  return { isSendError, isSending, sendEmails };
}
