import React, { useEffect, useState } from "react";
import SaaSSelector from "@/pages/general-settings/commonUserProvisioning/DepartmentsSaas/SaaSSelector";
import { useAuth0 } from "@auth0/auth0-react";
import { extractErrorMessage } from "@/utils/misc";
import { Typography } from "@mui/material";
import { Delete } from "@/pages/general-settings/commonUserProvisioning/DepartmentsSaas/Delete";
import { CompanySaasSelector } from "@/pages/phishing-simulation/company/CompanySaasSelector";
import axios from "axios";
import { Portlet, PortletBody } from "@/components/content/Portlet";

export function DepartmentsSaas({ tab, setTab }) {
  const [departments, setDepartments] = useState([]);
  const [saasList, setSaasList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const { getAccessTokenSilently } = useAuth0();

  // Populate the departments
  useEffect(() => {
    const f = async () => {
      const accessToken = await getAccessTokenSilently();

      try {
        const result = await axios.get(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/departments",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        setDepartments(result.data);
        setErrorMessage(null);
      } catch (error) {
        setErrorMessage(extractErrorMessage(error));
      }
    };

    // noinspection JSIgnoredPromiseFromCall
    f();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const f = async () => {
      // update only when the array is not empty
      if (departments.length > 0) {
        const accessToken = await getAccessTokenSilently();

        try {
          await axios.put(
            import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/departments",
            departments,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },
          );
          setErrorMessage(null);
        } catch (error) {
          setErrorMessage(extractErrorMessage(error));
        }
      }
    };

    f();
  }, [departments, getAccessTokenSilently]);

  // Populate the saas list
  useEffect(() => {
    const f = async () => {
      const accessToken = await getAccessTokenSilently();

      try {
        const result = await axios.get(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/saas",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        setSaasList(result.data);
        setErrorMessage(null);
      } catch (error) {
        setErrorMessage(extractErrorMessage(error));
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    f();
  }, [getAccessTokenSilently]);

  return (
    <>
      <Portlet>
        <PortletBody>
          <div>
            <Typography style={{ color: "black" }}>
              Select the templates used by each department.
            </Typography>
          </div>
          {errorMessage !== null && (
            <Typography style={{ color: "red" }}>{errorMessage}</Typography>
          )}
          <br />

          <div className="row">
            <div className="col-md-6">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Department</th>
                    <th scope="col">Used Template tags</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "20%" }}>{"Everyone"}</td>
                    <td>
                      <CompanySaasSelector />
                    </td>
                  </tr>
                  {departments.map((department) => (
                    <tr key={department.id}>
                      <td style={{ width: "20%" }} children={department.name} />
                      <td>
                        <SaaSSelector
                          department={department}
                          departments={departments}
                          setDepartments={setDepartments}
                          saasList={saasList}
                        />
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <Delete
                          department={department}
                          departments={departments}
                          setDepartments={setDepartments}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </PortletBody>
      </Portlet>
    </>
  );
}
