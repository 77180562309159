import React from "react";
import { UsersBannersListContainer } from "@/pages/banners/users/UsersBannersListContainer";
import { ComingSoon } from "@/pages/banners/ComingSoon";
import { useSimulationResource } from "@/utils/ResourceGet";
import { FullSizeBigSpinner } from "@/components/Spinner";

export function Users() {
  const [company, errorCompany, isCompanyLoading] = useSimulationResource(
    "company",
    { banners_activated: false },
  );

  if (isCompanyLoading) {
    return <FullSizeBigSpinner />;
  }

  if (errorCompany) {
    return <div>{errorCompany}</div>;
  }

  if (!company.banners_activated) {
    return <ComingSoon />;
  }

  return <UsersBannersListContainer />;
}
