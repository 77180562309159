import React, { useEffect, useState } from "react";
import { SelectedDepartment } from "@/pages/general-settings/commonUserProvisioning/GroupsDepartments/SelectDepartment";
import { Typography } from "@mui/material";
import { useSimulationResource } from "@/utils/ResourceGet";
import { extractErrorMessage } from "@/utils/misc";
import { useAuth0 } from "@auth0/auth0-react";
import { AddDepartment } from "@/pages/general-settings/commonUserProvisioning/DepartmentsSaas/AddDepartment";
import axios from "axios";
import { DepartmentItem } from "@/pages/general-settings/commonUserProvisioning/GroupsDepartments/DepartmentItem";
import styled from "styled-components";
import { ButtonSpinner } from "@/components/Spinner";
import { Portlet, PortletBody } from "@/components/content/Portlet";

const compareUsersNotInAnyDepartment = (group1, group2) => {
  const value1 =
    group1.coverage.total_users - group1.coverage.users_with_department;
  const value2 =
    group2.coverage.total_users - group2.coverage.users_with_department;

  return value2 - value1;
};

const sortWithSameOrderAs = (orderedGroups) => (group1, group2) => {
  const idOrder = orderedGroups.map((group) => group.id);
  return idOrder.indexOf(group1.id) - idOrder.indexOf(group2.id);
};

const coverageValueColor = (value) => {
  if (value > 50) {
    return "green";
  }
  if (value > 30) {
    return "orange";
  }
  return "red";
};

const CoverageInformationText = styled(Typography)`
  display: inline-block;

  font-size: ${(props) => props.coveragevalue !== undefined && "24px"};
  margin-left: ${(props) => props.coveragevalue !== undefined && "6px"};
  color: ${(props) =>
    props.coveragevalue !== undefined
      ? coverageValueColor(props.coveragevalue)
      : "black"};
`;

const LoadingMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem;
`;

export function GroupsDepartments({ tab, setTab }) {
  const [groups, groupsError, groupsLoading, fetchGroups] =
    useSimulationResource("groups", []);
  const [sortedGroups, setSortedGroups] = useState([]);
  const [isFirstSort, setIsFirstSort] = useState(true);
  useEffect(() => {
    if (groups.length !== 0) {
      if (isFirstSort) {
        setSortedGroups(groups.slice().sort(compareUsersNotInAnyDepartment));
        setIsFirstSort(false);
      } else {
        setSortedGroups((sortedGroups) =>
          groups.slice().sort(sortWithSameOrderAs(sortedGroups)),
        );
      }
    }
  }, [groups, isFirstSort]);

  const [totalCoverage, , , fetchTotalCoverage] = useSimulationResource(
    "users/department_coverage",
    null,
  );
  const [departments, setDepartments] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently()
      .then(async (accessToken) => {
        const result = await axios.get(
          `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/departments`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          },
        );

        setDepartments(result.data);
        setErrorMessage(null);
      })
      .catch((error) => {
        setErrorMessage(extractErrorMessage(error));
      });
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (groupsError) {
      setErrorMessage(groupsError.message);
    }
  }, [groupsError]);

  const refreshCoverage = () => {
    fetchGroups();
    fetchTotalCoverage();
  };
  return (
    <Portlet>
      <PortletBody>
        <div>
          <Typography style={{ color: "black" }}>
            Match your groups with departments created in Mantra. Do not worry
            if all groups are not matched.
          </Typography>
          <CoverageInformationText>
            Total users who are in at least one department:
          </CoverageInformationText>
          {groupsLoading
            ? " ..."
            : totalCoverage && (
                <CoverageInformationText
                  coveragevalue={totalCoverage?.ratio_percent}
                >
                  {totalCoverage?.ratio_percent}%
                </CoverageInformationText>
              )}
        </div>
        <br />
        <div className="row">
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Department</th>
                </tr>
              </thead>
              <tbody>
                {departments
                  .sort((a, b) => b.id - a.id)
                  .map((department) => (
                    <DepartmentItem
                      key={department.id}
                      department={department}
                      departments={departments}
                      setDepartments={setDepartments}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <AddDepartment
            departments={departments}
            setDepartments={setDepartments}
          />
        </div>
        <br />
        <br />
        <div className="row">
          <div className="col-md-12">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Group</th>
                  <th scope="col">Users in group</th>
                  <th scope="col">
                    Users in group not covered by any department
                  </th>
                  <th scope="col">Department</th>
                </tr>
              </thead>
              <tbody>
                {sortedGroups.map((group) => (
                  <tr key={group.id}>
                    <td>{group.name}</td>
                    <td>{group.coverage.total_users}</td>
                    <td>
                      {group.coverage.total_users -
                        group.coverage.users_with_department}
                    </td>
                    {
                      <td>
                        <SelectedDepartment
                          departments={departments}
                          group={group}
                          onSelect={refreshCoverage}
                        />
                      </td>
                    }
                  </tr>
                ))}
              </tbody>
            </table>
            {groupsLoading && (
              <LoadingMessage>
                <ButtonSpinner size="2.5rem" thickness={4} color="neutral" />
                <span>Computing department coverage...</span>
              </LoadingMessage>
            )}
          </div>
        </div>
        {errorMessage !== null && (
          <div className="row">
            <Typography style={{ color: "red" }}>{errorMessage}</Typography>
          </div>
        )}
      </PortletBody>
    </Portlet>
  );
}
