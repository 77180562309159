import React, { useEffect, useMemo, useState, useCallback } from "react";
import ReactWebChat, {
  createDirectLine,
  createStore,
} from "botframework-webchat";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { BigSpinner } from "@/components/Spinner";

const BACKGROUND_COLOR = "#f0f1f6";

const styleSet = window.WebChat.createStyleSet({
  backgroundColor: "transparent",
  bubbleBackground: "white",
  bubbleBorderColor: BACKGROUND_COLOR,
  bubbleBorderRadius: 6,
  bubbleMinWidth: 100,
  bubbleMaxWidth: 550,
  bubbleFromUserBackground: "rgba(79, 19, 255, 0.15)",
  bubbleFromUserBorderRadius: 6,
});

styleSet.textContent = {
  ...styleSet.textContent,
  fontFamily: "'Silka', 'Arial', 'sans-serif'",
  fontSize: "16px",
  fontWeight: "400",
};

const styleOptions = {
  hideUploadButton: true,
  hideSendBox: true,
  botAvatarInitials: "A",
  botAvatarBackgroundColor: null,
  botAvatarImage: "/media/logos/alex_initials.png",
  sendTypingIndicator: true,
  typingAnimationBackgroundImage: null,
  typingAnimationDuration: 2500,
  typingAnimationHeight: 200,
  typingAnimationWidth: 100,
};

const WebchatContainer = styled.div`
  position: relative;

  background-color: ${BACKGROUND_COLOR};

  display: flex;
  justify-content: center;

  height: 100%;

  margin-right: -25px;
  margin-left: -25px;
  padding-left: 25px;

  & > div:not(#progress-bar) {
    height: 100%;
    width: 100%;

    & .webchat__basic-transcript__scrollable {
      padding: 0 20%;
    }

    & .markdown > p {
      margin: 0 6px;
    }

    & .webchat__adaptive-card-renderer {
      background-color: ${BACKGROUND_COLOR};
      margin: -15px 0 -15px -5px;
    }

    &
      .webchat--css-vliiq-rctxl4.webchat__stacked-layout
      .webchat__stacked-layout__attachment {
      background-color: transparent !important;
      width: fit-content;
    }

    & .ac-actionSet {
      display: flex;
      flex-direction: row !important;
      flex-wrap: wrap;
      gap: 5px;

      width: fit-content;

      & > button {
        border-color: #4f13ff;
        border-radius: 5px;
        background-color: transparent !important;

        color: #4f13ff !important;
        font-weight: 500 !important;
      }

      & > button[aria-pressed="true"] {
        background-color: #4f13ff !important;
        border-color: #4f13ff !important;
        color: white !important;
      }
    }
  }

  & > aside {
    position: absolute;
    width: 15%;

    z-index: 2;
  }
`;

const OptionsButtons = styled.aside`
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  padding-top: 15px;

  & > button {
    box-sizing: border-box;
    background: #eef7ff;
    border: 0.5px solid #a5a7ab;
    border-radius: 5px;

    padding: 5px;
    width: 150px;

    color: #1985ff;

    &:hover {
      background: #d5e8fa;
    }
  }
`;

const CourseTitle = styled.aside`
  top: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  & > div {
    padding: 0.5rem 1rem;
    border-bottom: 2px solid #c5c9e0;
    border-left: 2px solid #c5c9e0;
    border-bottom-left-radius: 10px;

    color: #140040;
    font-size: 12px;
    font-weight: 400;
  }
`;

const CourseProgressBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 8px;
  background-color: #d9cdf5;

  overflow: hidden;

  z-index: 3;

  &:after {
    content: "";
    display: inline-block;

    width: ${(props) => (props.value * 100) / props.max}%;
    height: 30px;
    background-color: #4700f7;

    transition: width 1s;
  }
`;

const BlockingText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: ${(props) => (props.shouldDisplay ? 1 : 0)};
  backdrop-filter: blur(${(props) => (props.shouldDisplay ? 7 : 0)}px);

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  z-index: 100;
  pointer-events: none;
`;

export function Webchat({
  connectionInfo,
  courseTitle,
  allInteractions,
  goBackPath,
  additionalButtons,
  loadingHistory,
}) {
  const navigate = useNavigate();

  const directLine = useMemo(() => {
    if (connectionInfo?.access_token) {
      return createDirectLine({
        conversationId: connectionInfo?.conversationId,
        token: connectionInfo?.access_token,
        watermark: connectionInfo?.watermark,
      });
    }
  }, [connectionInfo]);

  const [currentInteraction, setCurrentInteraction] = useState("");
  const [progress, setProgress] = useState(0);
  const normalize = useCallback(
    (content) =>
      typeof content === "string"
        ? content.replaceAll(/\r\n|\r|\n/g, "")
        : content,
    [],
  );

  useEffect(() => {
    const normalizedInteractions = allInteractions.map(normalize);
    const normalizedCurrent = normalize(currentInteraction);
    const step = normalizedInteractions.indexOf(normalizedCurrent) + 1;

    if (step > 0) {
      setProgress(step);
    }
  }, [currentInteraction, allInteractions, normalize]);

  const sectionEl = document.querySelector("section[role=feed]");
  const store = useMemo(
    () =>
      createStore({}, () => (next) => (action) => {
        if (action.type === "DIRECT_LINE/INCOMING_ACTIVITY") {
          const { activity } = action.payload;
          if (
            activity?.type === "message" &&
            activity?.from?.role === "bot" &&
            !activity?.attachments
          ) {
            setCurrentInteraction(activity?.text);
          }
        }
        return next(action);
      }),
    [],
  );

  useEffect(() => {
    if (sectionEl !== null) {
      const mutationObserver = new MutationObserver(() => {
        setTimeout(
          () => sectionEl.scrollIntoView({ behavior: "smooth", block: "end" }),
          250,
        );
      });
      mutationObserver.observe(sectionEl, {
        childList: true,
        attributes: false,
        subtree: false,
      });
      return () => mutationObserver.disconnect();
    }
  }, [sectionEl]);

  if (!connectionInfo?.user_id || !connectionInfo?.user_name) {
    return <div>Not ready</div>;
  }

  return (
    <WebchatContainer>
      <OptionsButtons>
        <button onClick={() => navigate(goBackPath)}>{"< Back"}</button>
        {additionalButtons}
      </OptionsButtons>
      <ReactWebChat
        directLine={directLine}
        userID={connectionInfo.user_id}
        username={connectionInfo.user_name}
        styleSet={styleSet}
        styleOptions={styleOptions}
        store={store}
      />
      <CourseTitle>
        <div>{courseTitle}</div>
      </CourseTitle>
      <BlockingText shouldDisplay={loadingHistory}>
        <BigSpinner />
      </BlockingText>
      {allInteractions.length > 0 && (
        <CourseProgressBar
          id="progress-bar"
          value={progress}
          max={allInteractions.length}
        />
      )}
    </WebchatContainer>
  );
}
