export const LANDING_PAGE_MARK_AS_SAFE = {
  fr: {
    add_to_safe: "a été ajouté à vos contacts vérifiés",
    not_display_again:
      "Nous n’afficherons plus de bannières sur les emails de cet expéditeur",
    close: "Vous pouvez fermer cet onglet",
    footer: "Pour toute demande, contactez",
  },
  en: {
    add_to_safe: "has been added to your safe senders list",
    not_display_again:
      "We won't display any banner on future emails coming from this sender",
    close: "You can close this tab",
    footer: "For any inquiry, please contact",
  },
};

export const LANDING_PAGE_REPORT_AS_THREAT = {
  fr: {
    email_send: "L'email a été transmis à votre département IT",
    wait_analysis: "Nous vous recommandons d'attendre leur analyse",
    before_interacting: " avant d'interagir avec cet email ou son expéditeur",
    close: "Vous pouvez fermer cet onglet",
    footer: "Pour toute demande, contactez",
  },
  en: {
    email_send: "The email has been forwarded to your IT department",
    wait_analysis: "We recommend you to wait for their analysis",
    before_interacting: "before interacting with this email or its sender",
    close: "You can close this tab",
    footer: "For any inquiry, please contact",
  },
};

export const LANDING_PAGE_ERROR = {
  fr: {
    action_not_allowed:
      "Cette action n’est pas autorisée, vous ne pouvez signaler que les emails dont vous êtes le destinataire.",
    an_error_has_occurred: "Une erreur est survenue",
  },
  en: {
    action_not_allowed:
      "This action is not permitted; you can only report emails that you are the recipient of.",
    an_error_has_occurred: "An error has occurred",
  },
};

export const MARK_AS_SAFE_ERROR = {
  fr: {
    sender_has_not_been_added:
      "L'expéditeur n'a pas été ajouté a vos contacts vérifiés",
    footer: "Pour toute demande, contactez",
  },
  en: {
    sender_has_not_been_added:
      "The sender has not been added to your safe sender list",
    footer: "For any inquiry, please contact",
  },
};

export const REPORT_AS_THREAT_ERROR = {
  fr: {
    footer: "Pour toute demande, contactez",
  },
  en: {
    footer: "For any inquiry, please contact",
  },
};
