import { DocumentationComponent } from "@/pages/awareness/setup/Teams/DocumentationComponent";
import { GrantPermissionComponent } from "@/pages/awareness/setup/Teams/GrantPermissionComponent";
import { RadioFormControl } from "@/pages/awareness/setup/Teams/RadioFormControl";
import { StyledBox } from "@/pages/awareness/setup/Teams/StyledBox";
import { StyledTypo } from "@/pages/awareness/setup/Teams/StyledTypo";
import { useUserContext } from "@/utils/contexts/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContentText,
  DialogTitle,
  Divider,
  Snackbar,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

export const Teams = () => {
  const [deploymentType, setDeploymentType] = useState("company");
  const [deployedUsers, setDeployedUsers] = useState([]);
  const [deployedDepartments, setDeployedDepartments] = useState([]);
  const [nbBotsDeployed, setNbBotsDeployed] = useState(0);
  const [deployInProgress, setDeployInProgress] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const user = useUserContext();
  const [deployBotConsent, setDeployBotConsent] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  useEffect(() => {
    async function f() {
      const accessToken = await getAccessTokenSilently();
      try {
        const { data } = await axios.get(
          import.meta.env.VITE_APP_ENDPOINT_LEARNING +
            "/teams/installation/" +
            user.base_company.id,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          },
        );
        setDeployedUsers(data.deployed_users);
        setDeployedDepartments(data.deployed_departments);
        setDeployBotConsent(data.deploy_bot_consent);
        setDeploymentType(data.mode);
        setNbBotsDeployed(data.users_deployed);
      } catch (e) {
        // if we can't reach simulation, fallback to the auth0 user
      }
    }

    f();
  }, [getAccessTokenSilently, user]);

  const onClickDeploy = () => {
    if (
      nbBotsDeployed > 0 &&
      ["users", "departments"].includes(deploymentType)
    ) {
      setShowConfirmationPopup(true);
    } else {
      deployUsers();
    }
  };

  const deployUsers = async () => {
    const accessToken = await getAccessTokenSilently();
    let url = "";
    let param;
    if (deploymentType === "company") {
      url = import.meta.env.VITE_APP_ENDPOINT_LEARNING + "/teams/deploy-all";
      param = { company_id: user.base_company.id };
    } else if (deploymentType === "users") {
      url = import.meta.env.VITE_APP_ENDPOINT_LEARNING + "/teams/deploy";
      const usersID = deployedUsers.map((user) => user.id);
      param = {
        company_id: user.base_company.id,
        user_ids: usersID,
      };
    } else if (deploymentType === "departments") {
      url =
        import.meta.env.VITE_APP_ENDPOINT_LEARNING + "/teams/deploy-department";
      const departmentsID = deployedDepartments.map(
        (department) => department.id,
      );
      param = {
        company_id: user.base_company.id,
        department_ids: departmentsID,
      };
    }
    setDeployInProgress(true);

    axios.post(url, param, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
  };

  return (
    <Card>
      <CardHeader variant={"h2"} title={"Microsoft Teams Configuration"} />
      <Divider sx={{ marginBottom: "1.5rem" }} />
      <CardContent
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Box sx={{ width: "600px" }}>
          <DocumentationComponent />
          <GrantPermissionComponent
            user={user}
            deployBotConsent={deployBotConsent}
          />

          <StyledTypo variant={"h2"}>Deployment Type</StyledTypo>
          <RadioFormControl
            deploymentType={deploymentType}
            setDeploymentType={setDeploymentType}
            setDeployedUsers={setDeployedUsers}
            deployedUsers={deployedUsers}
            setDeployedDepartments={setDeployedDepartments}
            deployedDepartments={deployedDepartments}
          />
          <StyledBox>
            <Typography color={"text.secondary"} sx={{ paddingTop: "1rem" }}>
              Alex is currently deployed on {nbBotsDeployed} users
            </Typography>
          </StyledBox>

          <StyledBox sx={{ justifyContent: "flex-end" }}>
            {!deployInProgress && (
              <Button
                variant={"contained"}
                disabled={!deployBotConsent}
                sx={{ marginTop: "2rem" }}
                onClick={onClickDeploy}
              >
                Deploy bot to user
              </Button>
            )}
          </StyledBox>
          <Snackbar
            open={deployInProgress}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Alex is currently being installed. Reload the page to see the
              number of users deployed
            </Alert>
          </Snackbar>
        </Box>
      </CardContent>
      <Dialog open={showConfirmationPopup}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Alex will be deployed only on those selected{" "}
            {deploymentType === "departments" ? "departments" : "users"}. All
            other users that have had the bot installed will have it removed.
          </DialogContentText>
          <DialogActions>
            <Button onClick={() => setShowConfirmationPopup(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setShowConfirmationPopup(false);
                deployUsers();
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Card>
  );
};
