import React from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "@/components/content/Portlet";
import { DeploymentTest } from "@/pages/banners/setup/DeploymentTest";
import { ComingSoon } from "@/pages/banners/ComingSoon";
import { useSimulationResource } from "@/utils/ResourceGet";
import { FullSizeBigSpinner } from "@/components/Spinner";
import Grid2 from "@mui/material/Unstable_Grid2";
import { SampleEmails } from "@/pages/banners/setup/SampleEmails.tsx";
import { HistoricalAnalysis } from "@/pages/banners/setup/HistoricalAnalysis.tsx";

export function Setup() {
  const [company, errorCompany, isCompanyLoading] = useSimulationResource(
    "company",
    { banners_activated: false },
  );

  if (isCompanyLoading) {
    return <FullSizeBigSpinner />;
  }

  if (errorCompany) {
    return <div>{errorCompany}</div>;
  }

  if (!company.banners_activated) {
    return <ComingSoon />;
  }

  return (
    <>
      <Grid2>
        <Portlet>
          <PortletHeader title="Setup" />
          <PortletBody>
            <DeploymentTest />
          </PortletBody>
        </Portlet>
        <Portlet>
          <PortletHeader title="Sample emails" />
          <PortletBody>
            <SampleEmails />
          </PortletBody>
        </Portlet>
        <Portlet>
          <PortletHeader title="Historical analysis" />
          <PortletBody>
            <HistoricalAnalysis />
          </PortletBody>
        </Portlet>
      </Grid2>
    </>
  );
}
