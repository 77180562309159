import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { SimulationScheduling } from "@/pages/phishing-simulation/setup/SimulationScheduling";
import { DaysBetweenSimulations } from "@/pages/phishing-simulation/setup/DaysBetweenSimulations";
import { useUserContext } from "@/utils/contexts/UserContext";
import { SimulationAccess } from "@/types/user";

export function Preferences() {
  const user = useUserContext();
  const simulationAccess = user.current_company?.simulation_access;

  if (
    simulationAccess === SimulationAccess.NO_ACCESS ||
    simulationAccess === SimulationAccess.TRIAL_MODE
  ) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Typography variant="h5" align="center">
          {simulationAccess === SimulationAccess.NO_ACCESS
            ? "Your company doesn't have access to phishing simulations. Ask Mantra to provide full access to display this page."
            : "Your company is currently in audit mode. Ask Mantra to provide full access to display this page."}
        </Typography>
      </Box>
    );
  }

  if (simulationAccess === SimulationAccess.FULL_ACCESS) {
    return (
      <Stack spacing={3}>
        <SimulationScheduling />
        <DaysBetweenSimulations />
      </Stack>
    );
  }
}
