import { extractErrorMessage, getConfig } from "@/utils/misc";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "material-ui-snackbar-provider";
import { debounce } from "lodash";

export const supportedLanguages = [
  "en",
  "fr",
  "it",
  "es",
  "de",
  "fr_formal",
  "nl",
];

export const useTestLocalizedCourse = () => {
  const [isTestLocalizedCourseLoading, setIsTestLocalizedCourseLoading] =
    useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const sendTestLocalizedCourse = async (
    localized_course_id: number,
    user_id: number,
  ) => {
    setIsTestLocalizedCourseLoading(true);
    try {
      await axios.post(
        `${
          import.meta.env.VITE_APP_ENDPOINT_LEARNING
        }/localized-courses/${localized_course_id}/send/${user_id}`,
        {},
        await getConfig(getAccessTokenSilently),
      );

      const companyInfo = await axios.get(
        `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/company`,
        await getConfig(getAccessTokenSilently),
      );
      setIsTestLocalizedCourseLoading(false);
      if (companyInfo?.data?.messaging_platform === "webchat") {
        navigate("/webchat");
      }
      snackbar.showMessage("Course successfully sent");
    } catch (error) {
      setIsTestLocalizedCourseLoading(false);
      snackbar.showMessage(extractErrorMessage(error));
    }
  };
  return {
    sendTestLocalizedCourse: sendTestLocalizedCourse,
    isTestLocalizedCourseLoading: isTestLocalizedCourseLoading,
  };
};

export const useTestCourseUsers = () => {
  const { getAccessTokenSilently } = useAuth0();
  const snackbar = useSnackbar();

  const sendTestCourseUsers = async (
    course_id: number,
    user_ids: Array<number>,
  ) => {
    try {
      await axios.post(
        `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/courses/send_to_list`,
        { course_id: course_id, user_ids: user_ids },
        await getConfig(getAccessTokenSilently),
      );

      snackbar.showMessage("Course successfully sent");
    } catch (error) {
      snackbar.showMessage(extractErrorMessage(error));
    }
  };

  return {
    sendTestCourseUsers: sendTestCourseUsers,
  };
};
export const useTestCourseDepartments = () => {
  const { getAccessTokenSilently } = useAuth0();
  const snackbar = useSnackbar();

  const sendTestCourseDepartments = async (
    course_id: number,
    department_ids: Array<number>,
  ) => {
    try {
      await axios.post(
        `${
          import.meta.env.VITE_APP_ENDPOINT_LEARNING
        }/courses/send_to_departments`,
        { course_id: course_id, department_ids: department_ids },
        await getConfig(getAccessTokenSilently),
      );

      snackbar.showMessage("Course successfully sent");
    } catch (error) {
      snackbar.showMessage(extractErrorMessage(error));
    }
  };

  return {
    sendTestCourseDepartments: sendTestCourseDepartments,
  };
};
export const useTestCourseCompany = () => {
  const { getAccessTokenSilently } = useAuth0();
  const snackbar = useSnackbar();

  const sendTestCourseCompany = async (course_id: number) => {
    try {
      await axios.post(
        `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/courses/send_to_company`,
        { course_id: course_id },
        await getConfig(getAccessTokenSilently),
      );

      snackbar.showMessage("Course successfully sent");
    } catch (error) {
      snackbar.showMessage(extractErrorMessage(error));
    }
  };

  return {
    sendTestCourseCompany: sendTestCourseCompany,
  };
};

export const useDebounceRequest = (
  requestFn,
  { successMessage = null, errorMessage = null } = {},
  time = 1000,
) => {
  const snackbar = useSnackbar();
  const debouncedRequest = debounce(async (v) => {
    const promiseResponse = await requestFn(v);
    // TODO improve this behavior
    if (successMessage && promiseResponse.data !== undefined) {
      snackbar.showMessage(successMessage);
    } else if (errorMessage && promiseResponse.error !== undefined) {
      snackbar.showMessage(errorMessage);
    }
  }, time);
  const memoizedDebouncedRequest = useMemo(
    () => debouncedRequest,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return memoizedDebouncedRequest;
};

export function useDepartements() {
  const { getAccessTokenSilently } = useAuth0();
  const [departments, setDepartments] = useState([]);
  const getDepartments = useCallback(async () => {
    const response = await axios.get(
      `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/departments`,
      await getConfig(getAccessTokenSilently),
    );
    setDepartments(response.data);
  }, [getAccessTokenSilently]);
  useEffect(() => {
    getDepartments();
  }, [getDepartments]);

  return { departments };
}
