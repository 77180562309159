import React, { useMemo } from "react";
import { Typography } from "@mui/material";
import { Users } from "@/pages/general-settings/UsersManagement/Users";
import { GSuiteSync } from "@/pages/general-settings/GSuite/GSuiteSync";
import { OfficeSync } from "@/pages/general-settings/Office/OfficeSync";
import { OktaSync } from "@/pages/general-settings/Okta/OktaSync";
import { useUserContext } from "@/utils/contexts/UserContext";
import PermissionsAttribution from "@/pages/general-settings/permissions-attribution/PermissionsAttribution";
import StickyHeader from "@/components/StickyHeader";
import Tabs, {
  TabObject,
  TabsContextProvider,
  useTabsContext,
} from "@/components/controls/Tabs";
import { capitalize } from "@/utils/strings";

export default function GeneralSettings() {
  const baseInfo = useUserContext();
  const companyProvider = baseInfo.current_company.provider;
  const tabs = useMemo<TabObject[]>(
    () => [
      {
        label: "Admins",
        anchor: "admins",
        component: <PermissionsAttribution />,
      },
      {
        label: "User management",
        anchor: "users-management",
        component: <Users />,
      },
      {
        label: `${capitalize(companyProvider)} Users Provisioning`,
        anchor: "users-provisioning",
        component: <Provisioning companyProvider={companyProvider} />,
      },
    ],
    [companyProvider],
  );

  return (
    <TabsContextProvider value={{ tabs: tabs }}>
      <GeneralSettingsPanels />
    </TabsContextProvider>
  );
}

export function GeneralSettingsPanels() {
  const { currentTabComponent } = useTabsContext();

  return (
    <>
      <StickyHeader height="120px">
        <Typography variant="h2">General Settings</Typography>
        <Tabs />
      </StickyHeader>
      {currentTabComponent}
    </>
  );
}

const Provisioning: React.FC<{ companyProvider: string }> = ({
  companyProvider,
}) => {
  switch (companyProvider) {
    case "GOOGLE":
      return <GSuiteSync />;
    case "OFFICE":
      return <OfficeSync />;
    case "OKTA":
      return <OktaSync />;
    default:
      return <div></div>;
  }
};
