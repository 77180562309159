import React from "react";
import { StepperStateKey } from "./SetupChecklistTypes";
import MailingPermissions from "./stepComponents/MailingPermissions";
import ProvisioningPermissions from "./stepComponents/ProvisioningPermissions";
// import TestDelivery from './stepComponents/TestDelivery';
import ProvisionAtLeastOneOtherUser from "./stepComponents/ProvisionAtLeastOneOtherUser";
import CompanyScan from "./stepComponents/CompanyScan";
import MapGroupsToDepartments from "./stepComponents/MapGroupsToDepartments";
import AttributeTemplatesToDepartments from "./stepComponents/AttributeTemplatesToDepartments";
import TestDelivery from "./stepComponents/TestDelivery";

const SetupCurrentComponent = ({
  activeStep,
}: {
  activeStep: StepperStateKey;
}) => {
  switch (activeStep) {
    case StepperStateKey.MAILING_PERMISSIONS:
      return <MailingPermissions />;
    case StepperStateKey.PROVISIONING_PERMISSIONS:
      return <ProvisioningPermissions />;
    case StepperStateKey.PROVISION_ONE_USER:
      return <ProvisionAtLeastOneOtherUser />;
    case StepperStateKey.SIMULATION_DELIVERY_TEST:
      return <TestDelivery />;
    case StepperStateKey.COMPANY_SCAN:
      return <CompanyScan />;
    case StepperStateKey.MAP_GROUPS_DEPARTMENTS:
      return <MapGroupsToDepartments />;
    case StepperStateKey.ATTRIBUTE_TEMPLATES_DEPARTMENTS:
      return <AttributeTemplatesToDepartments />;
  }
};

export default SetupCurrentComponent;
