import React, { useMemo, useState } from "react";
import CoursesCatalogStyle from "./CoursesCatalog.module.css";
import { BigSpinner } from "@/components/Spinner";
import { useCourseCatalog } from "./hooks";
import { useHasCourseCatalogAdminRights } from "@/utils/misc";
import {
  EMPTY_COURSE_CATALOG_TRANSLATIONS,
  USER_NOT_SETUP_COURSE_CATALOG_TRANSLATIONS,
} from "@/pages/awareness/courses/utils/translations";
import { ErrorCatalogMessage } from "@/pages/awareness/courses/courses-catalog/ErrorCatalogMessage";
import {
  selectCourseBySearch,
  selectIsDemoAcme,
  selectUserInfos,
} from "@/pages/awareness/courses/courses-catalog/courseCatalogSlice";
import { useSelector } from "react-redux";
import { SubscribeAllModal } from "@/pages/awareness/courses/courses-catalog/SubscribeAllModal";
import StickyHeader from "@/components/StickyHeader";
import { ResearchBar } from "@/pages/awareness/courses/courses-catalog/ResearchBar";
import { CourseFilterBar } from "@/pages/awareness/courses/courses-catalog/CourseFilterBar";
import { CourseCardsContainerListDraggable } from "@/pages/awareness/courses/courses-catalog/demo-course-catalog/CourseCardsContainerListDraggable";
import { CourseCardsContainerList } from "@/pages/awareness/courses/courses-catalog/CourseCardsContainerList";

type CourseCatalogProps = {
  isPublicWebchat: boolean;
};

export const CoursesCatalog = ({ isPublicWebchat }: CourseCatalogProps) => {
  const isCourseCatalogAdmin = useHasCourseCatalogAdminRights();
  const { isLoading, error } = useCourseCatalog(isPublicWebchat);
  const userInfos = useSelector(selectUserInfos);
  const [openModal, setOpenModal] = useState(false);
  const filteredCourse = useSelector(selectCourseBySearch);
  const isDemoAcme: boolean = useSelector(selectIsDemoAcme);

  const empty_catalog_translation = useMemo(
    () =>
      EMPTY_COURSE_CATALOG_TRANSLATIONS[userInfos.language] ||
      EMPTY_COURSE_CATALOG_TRANSLATIONS["en"],
    [userInfos.language],
  );

  const user_not_setup_translation = useMemo(
    () =>
      USER_NOT_SETUP_COURSE_CATALOG_TRANSLATIONS[userInfos.language] ||
      USER_NOT_SETUP_COURSE_CATALOG_TRANSLATIONS["en"],
    [userInfos.language],
  );
  const onClickSubscribeAll = async () => {
    setOpenModal(true);
  };

  if (isLoading) {
    return (
      <div className={CoursesCatalogStyle.cardCatalogProgressContainer}>
        <BigSpinner shouldDisplayText={true} text="This may take a while..." />
      </div>
    );
  }

  if (error) {
    return <div>Error: Unable to fetch courses</div>;
  }
  if (!userInfos.bot_installed && !isPublicWebchat && !isDemoAcme) {
    return (
      <ErrorCatalogMessage
        error_catalog_message_text={user_not_setup_translation}
      />
    );
  }
  return (
    <>
      {isCourseCatalogAdmin && !isPublicWebchat && (
        <StickyHeader height="120px">
          <ResearchBar onClickSubscribeAll={onClickSubscribeAll} />
          <CourseFilterBar />
        </StickyHeader>
      )}
      {filteredCourse.length > 0 && isCourseCatalogAdmin !== null ? (
        <>
          {isDemoAcme ? (
            <CourseCardsContainerListDraggable
              isCourseCatalogAdmin={isCourseCatalogAdmin}
              isPublicWebchat={isPublicWebchat}
              courses={filteredCourse}
              learningUserInfos={userInfos}
            />
          ) : (
            <CourseCardsContainerList
              isCourseCatalogAdmin={isCourseCatalogAdmin}
              isPublicWebchat={isPublicWebchat}
              courses={filteredCourse}
              learningUserInfos={userInfos}
            />
          )}
        </>
      ) : (
        !isCourseCatalogAdmin && (
          <ErrorCatalogMessage
            error_catalog_message_text={empty_catalog_translation}
          />
        )
      )}
      <SubscribeAllModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        filteredCourse={filteredCourse}
        userInfos={userInfos}
      />
    </>
  );
};
