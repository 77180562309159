import React, { useEffect, useRef, useState } from "react";
import { extractErrorMessage, validateEmail } from "@/utils/misc";
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "material-ui-snackbar-provider";
import axios from "axios";
import { TextareaAutosize } from "@mui/base";

export function Blacklist() {
  const [emails, setEmails] = useState("");
  const previousEmails = useRef(""); // Previous state of nb blacklisted emails
  const { getAccessTokenSilently } = useAuth0();
  const snackbar = useSnackbar();

  // Populate the departments
  useEffect(() => {
    const f = async () => {
      const accessToken = await getAccessTokenSilently();

      try {
        const result = await axios.get(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
            "/users/sync/blacklist",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        // merge the emails array to create a single string
        setEmails(result.data.blacklisted.join("\n"));
      } catch (error) {
        snackbar.showMessage(extractErrorMessage(error) as string);
      }
    };

    // noinspection JSIgnoredPromiseFromCall
    f();
  }, [getAccessTokenSilently, snackbar]);

  const onChange = (e) => {
    setEmails(e.target.value);

    // Split text entered by line, remove whitespaces and filter empty and keep only validated emails addresses
    const cleanEmailList = e.target.value
      .split("\n")
      .map((e) => e.trim())
      .map((e) => e.toLowerCase())
      .filter((e) => e !== "")
      .filter((e) => validateEmail(e));

    const setBlacklist = async () => {
      const accessToken = await getAccessTokenSilently();
      try {
        await axios.put(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
            "/users/sync/blacklist",
          {
            blacklisted: cleanEmailList,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        snackbar.showMessage(
          cleanEmailList.length + " blacklisted email(s) saved",
        );
      } catch (error) {
        snackbar.showMessage(extractErrorMessage(error) as string);
      }
    };
    const cleanSerializedList = JSON.stringify(cleanEmailList.sort());

    if (cleanSerializedList !== previousEmails.current) {
      // noinspection JSIgnoredPromiseFromCall
      setBlacklist();
      // Set the nb emails params for later use
      previousEmails.current = cleanSerializedList;
    }
  };

  return (
    <div>
      List of users emails address not to import. Add one email per line.
      <div>
        <TextareaAutosize
          defaultValue={emails}
          minRows={3}
          placeholder="system@yourcompany.com"
          onChange={(e) => onChange(e)}
          style={{ marginTop: "8px", width: "30%" }}
        />
      </div>
    </div>
  );
}
