import React, { useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { extractErrorMessage } from "@/utils/misc";
import axios from "axios";

export function DeploymentTest() {
  const [showTestSent, setShowTestSent] = useState(false);
  const [errorMessageTest, setErrorMessageTest] = useState("");
  const [outboundRequest, setOutboundRequest] = useState(false);
  const { user, getAccessTokenSilently } = useAuth0();

  const sendEmail = async () => {
    const accessToken = await getAccessTokenSilently();
    try {
      setOutboundRequest(true);
      await axios.post(
        import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
          "/users/gmail_controller_setup_email",
        {},
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );
      setShowTestSent(true);
      setErrorMessageTest(null);
      setOutboundRequest(false);
    } catch (error) {
      setShowTestSent(false);
      setErrorMessageTest(extractErrorMessage(error));
      setOutboundRequest(false);
    }
  };

  return (
    <div>
      Click the button below to check deployment of Smart Banners. You should
      receive an email with a green banner in your inbox. <br />
      If you don't receive it, <a href="mailto:team@mantra.ms">contact us</a>.
      <br />
      <br />
      <Grid item xs>
        <Grid>
          <div>
            <Button
              variant="contained"
              onClick={sendEmail}
              disabled={outboundRequest}
            >
              Test deployment
            </Button>
            <Box
              component="div"
              sx={{
                p: 2,
                fontSize: "0.9rem",
                fontStyle: "italic",
                display: "inline",
              }}
            >
              You must be engaged to receive Smart Banners (in{" "}
              <a href="/banners/users">Users tab</a>).
            </Box>
          </div>
        </Grid>
      </Grid>
      {showTestSent && (
        <div style={{ color: "green" }} className="mt-3">
          Email successfully sent to {user.email}.
        </div>
      )}
      {errorMessageTest && (
        <div style={{ color: "red" }}>{errorMessageTest}</div>
      )}
    </div>
  );
}
