import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import EmailMessage from "@/pages/phishing-simulation/dashboard/emailmessage/EmailMessage";
import Dashboard from "@/pages/phishing-simulation/dashboard/Dashboard";
import { CourseSharing } from "@/pages/awareness/courses/corporate-course-sharing/CourseSharing";
import { ImportWizard } from "@/pages/general-settings/UsersManagement/csvImport/ImportWizard";
import { User } from "@/pages/general-settings/UsersManagement/User";
import { ThreatsFeatureGate } from "@/pages/threats/ThreatsFeatureGate";
import { MyProgress } from "@/pages/my-progress/MyProgress";
import { HomeRedirect } from "@/router/HomeRedirect";
import { ErrorPage404 } from "@/pages/errors/ErrorPage404";
import { Setup as AwarenessSetup } from "@/pages/awareness/setup/Setup";
import { AwarenessDashboard } from "@/pages/awareness/dashboard/AwarenessDashboard";
import { EnterpriseWebchat } from "@/pages/webchat/EnterpriseWebchat";
import { PublicWebchat } from "@/pages/webchat/PublicWebchat";
import { BannerDashboard } from "@/pages/banners/dashboard/BannerDashboard";
import { BannerPreferencesDashboard } from "@/pages/banners/preferences/BannerPreferencesDashboard";
import { Dashboard as BrowserDefenderDashboard } from "@/pages/browser-defender/Dashboard";
import { Users as BrowserDefenderUsers } from "@/pages/browser-defender/Users";
import { UserDetails as BrowserDefenderUserDetails } from "@/pages/browser-defender/UserDetails";
import { Setup as BrowserDefenderSetup } from "@/pages/browser-defender/Setup";
import PasswordMisusePage from "@/pages/browser-defender/PasswordMisuse";
import DangerousDownloadsPage from "@/pages/browser-defender/DangerousDownloads";
import { DangerousBrowsing } from "@/pages/browser-defender/DangerousBrowsing";
import { ExtensionGovernance } from "@/pages/browser-defender/ExtensionGovernance";
import { WeakPasswords } from "@/pages/browser-defender/WeakPasswords";
import { CourseInfo } from "@/pages/awareness/courses/course-editor/CourseInfo";
import PhishingSimulationCompany from "@/pages/phishing-simulation/PhishingSimulationCompany";
import { Setup as PhishingSimulationSetup } from "@/pages/phishing-simulation/setup/Setup";
import Audit from "@/pages/phishing-simulation/audit/Audit";
import { Setup as BannerSetup } from "@/pages/banners/setup/Setup";
import { Users as BannerUsers } from "@/pages/banners/users/Users";
import GeneralSettings from "@/pages/general-settings/GeneralSettings";
import { Support } from "@/pages/support/Support";
import { RoutePropertiesWrapper } from "./RoutePropertiesWrapper";
import { ReportAsThreat } from "@/pages/banners/report-as-threat/ReportAsThreat";
import { MarkAsSafe } from "@/pages/banners/mark-as-safe/MarkAsSafe";
import CustomCampaign from "@/pages/phishing-simulation/CustomCampaign/CustomCampaign";
import PanelCreateCustomCampaign from "@/pages/phishing-simulation/CustomCampaign/CustomCampaignCreate/PanelCreateCustomCampaign";
import PanelCustomCampaignDetails from "@/pages/phishing-simulation/CustomCampaign/CustomCampaignDetails/PanelCustomCampaignDetails";
import { Permission } from "@/pages/general-settings/permissions-attribution/permissions";
import { CoursesCatalog } from "@/pages/awareness/courses/courses-catalog/CoursesCatalog";
import { TemplateCatalog } from "@/pages/templates/TemplateCatalog";
import { Push } from "@/pages/awareness/push/Push";

export type RouteType = {
  path: string;
  element: JSX.Element;
  admin?: boolean;
  corporate_admin?: boolean;
  hasPadding?: boolean;
  permissions?: Permission[];
  hasMenu?: boolean;
  webchatActivatedOnly?: boolean;
};

const AUTHENTICATED_ROUTES: RouteType[] = [
  {
    path: "/phishing-simulation/dashboard",
    element: <Dashboard isCorporateView={false} />,
    permissions: [Permission.SIMULATION_DASHBOARD],
  },
  {
    path: "/phishing-simulation/custom-campaign",
    element: <CustomCampaign />,
    permissions: [Permission.SIMULATION_CUSTOM_CAMPAIGN],
  },
  {
    path: "/phishing-simulation/custom-campaign/create",
    element: <PanelCreateCustomCampaign />,
    permissions: [Permission.SIMULATION_CUSTOM_CAMPAIGN],
  },
  {
    path: "/phishing-simulation/custom-campaign/details",
    element: <PanelCustomCampaignDetails />,
    permissions: [Permission.SIMULATION_CUSTOM_CAMPAIGN],
  },
  {
    path: "/phishing-simulation/templates",
    element: <TemplateCatalog />,
    permissions: [Permission.SIMULATION_TEMPLATE_CATALOG],
  },
  {
    path: "/phishing-simulation/company",
    element: <PhishingSimulationCompany />,
    permissions: [Permission.SETTINGS_COMPANY_SCAN],
  },
  {
    path: "/phishing-simulation/audit",
    element: <Audit />,
    permissions: [Permission.SIMULATION_AUDIT],
  },
  {
    path: "/phishing-simulation/setup",
    element: <PhishingSimulationSetup />,
    permissions: [Permission.SIMULATION_SETUP],
  },

  {
    path: "/corporate/dashboard",
    element: <Dashboard isCorporateView={true} />,
    permissions: [Permission.CORPORATE_ADMIN],
  },

  {
    path: "/corporate/course-sharing",
    element: <CourseSharing />,
    permissions: [Permission.CORPORATE_ADMIN],
  },

  {
    path: "/awareness/dashboard",
    element: <AwarenessDashboard />,
    permissions: [Permission.AWARENESS_DASHBOARD],
  },
  {
    path: "/awareness/courses",
    element: <CoursesCatalog isPublicWebchat={false} />,
    permissions: [
      Permission.AWARENESS_COURSE_CATALOG_USER,
      Permission.AWARENESS_COURSE_CATALOG_ADMIN,
    ],
  },
  {
    path: "/awareness/push",
    element: <Push />,
  },
  {
    path: "/awareness/courses/:id",
    element: <CourseInfo />,
    permissions: [Permission.AWARENESS_COURSE_CATALOG_ADMIN],
  },
  {
    path: "/awareness/setup",
    element: <AwarenessSetup />,
    permissions: [Permission.AWARENESS_SETUP],
  },

  {
    path: "/banners/dashboard",
    element: <BannerDashboard />,
    permissions: [Permission.BANNERS],
  },
  {
    path: "/banners/setup",
    element: <BannerSetup />,
    permissions: [Permission.BANNERS],
  },
  {
    path: "/banners/users",
    element: <BannerUsers />,
    permissions: [Permission.BANNERS],
  },
  {
    path: "/banners/company/preferences",
    element: <BannerPreferencesDashboard />,
    permissions: [Permission.BANNERS],
  },
  {
    path: "/banners/report-as-threat",
    element: <ReportAsThreat />,
    permissions: [Permission.BASIC_USER_PERMISSIONS],
    hasMenu: false,
  },
  {
    path: "/banners/mark-as-safe",
    element: <MarkAsSafe />,
    hasMenu: false,
    permissions: [Permission.BASIC_USER_PERMISSIONS],
  },

  {
    path: "/browser-defender/dashboard",
    element: <BrowserDefenderDashboard />,
    permissions: [Permission.BROWSER_DEFENDER],
  },
  {
    path: "/browser-defender/password-misuse",
    element: <PasswordMisusePage />,
    permissions: [Permission.BROWSER_DEFENDER],
  },
  {
    path: "/browser-defender/dangerous-downloads",
    element: <DangerousDownloadsPage />,
    permissions: [Permission.BROWSER_DEFENDER],
  },
  {
    path: "/browser-defender/dangerous-browsing",
    element: <DangerousBrowsing />,
    permissions: [Permission.BROWSER_DEFENDER],
  },
  {
    path: "/browser-defender/extension-governance",
    element: <ExtensionGovernance />,
    permissions: [Permission.BROWSER_DEFENDER],
  },
  {
    path: "/browser-defender/weak-passwords",
    element: <WeakPasswords />,
    permissions: [Permission.BROWSER_DEFENDER],
  },
  {
    path: "/browser-defender/users",
    element: <BrowserDefenderUsers />,
    permissions: [Permission.BROWSER_DEFENDER],
  },
  {
    path: "/browser-defender/user-details",

    element: <BrowserDefenderUserDetails />,
  },
  {
    path: "/browser-defender/setup",
    element: <BrowserDefenderSetup />,
    permissions: [Permission.BROWSER_DEFENDER],
  },

  {
    path: "/threats",
    element: <ThreatsFeatureGate />,
    permissions: [Permission.THREATS],
  },

  {
    path: "/my-progress",
    element: <MyProgress />,
    permissions: [Permission.MY_PROGRESS],
  },

  {
    path: "/general-settings",
    element: <GeneralSettings />,
    permissions: [Permission.SETTINGS_GENERAL],
  },
  {
    path: "/users/import",
    element: <ImportWizard />,
    permissions: [Permission.SETTINGS_GENERAL],
  },

  {
    path: "/users/:userId",
    element: <User />,
    permissions: [Permission.BASIC_ADMIN_PERMISSIONS],
  },

  {
    path: "/dashboard/emailmessage",
    element: <EmailMessage />,
    permissions: [Permission.SIMULATION_DASHBOARD],
  },
  {
    path: "/webchat",
    element: <EnterpriseWebchat />,
    webchatActivatedOnly: true,
    hasPadding: false,
    permissions: [Permission.BASIC_USER_PERMISSIONS],
  },

  {
    path: "/support",
    element: <Support />,
    permissions: [Permission.SUPPORT],
  },

  { path: "/", element: <HomeRedirect /> },
  { path: "/*", element: <ErrorPage404 /> },
];

const REDIRECTIONS = [
  { from: "/dashboard", to: "/phishing-simulation/dashboard" },
  { from: "/phishing-simulation", to: "/phishing-simulation/dashboard" },

  { from: "/awareness", to: "/awareness/dashboard" },
  { from: "/demo/awareness", to: "/demo/awareness/courses" },

  { from: "/banners", to: "/banners/dashboard" },

  { from: "/browser-defender", to: "/browser-defender/dashboard" },

  { from: "/settings", to: "/general-settings" },
];

const DEMO_ROUTES = [
  {
    path: "awareness/courses/",
    element: <CoursesCatalog isPublicWebchat={true} />,
  },
  { path: "webchat/:userId", element: <PublicWebchat />, hasPadding: false },
  {
    path: "webchat/all/:userId",
    element: <PublicWebchat all={true} />,
    hasPadding: false,
  },
];

export const AppRoutes = () => {
  return (
    <Routes>
      {AUTHENTICATED_ROUTES.map((route) => (
        <Route
          path={route.path}
          element={
            <RoutePropertiesWrapper route={route}>
              {route.element}
            </RoutePropertiesWrapper>
          }
          key={route.path}
        />
      ))}
      {REDIRECTIONS.map((redirection) => (
        <Route
          path={redirection.from}
          element={<Navigate to={redirection.to} />}
          key={`from_${redirection.from}_to_${redirection.to}`}
        />
      ))}
      <Route path={"demo"}>
        {DEMO_ROUTES.map((route) => (
          <Route
            path={route.path}
            element={
              <RoutePropertiesWrapper route={route} isAuthenticated={false}>
                {route.element}
              </RoutePropertiesWrapper>
            }
            key={`demo_${route.path}`}
          />
        ))}
      </Route>
    </Routes>
  );
};
