import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import useBannerUsers from "@/pages/banners/remote-apis/bannerUsers";
import {
  useSendEmail,
  useTemplateNames,
} from "@/pages/banners/remote-apis/bannerTemplates";
import styled from "@emotion/styled";

const StyledAutocomplete = styled(Autocomplete)(
  () =>
    `
    & .MuiChip-root {
        width: initial;
        fontWeight: 500;
      }
    `,
);

export function SampleEmails() {
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const {
    users,
    usersFetchingError: usersError,
    usersFetchIsPending: usersPending,
  } = useBannerUsers(true);

  const {
    templates,
    error: templatesError,
    isPending: templatesPending,
  } = useTemplateNames();

  const { isSending, sendEmails } = useSendEmail();

  const [buttonActivated, setButtonActivated] = useState(false);

  useEffect(() => {
    setButtonActivated(
      !isSending &&
        usersError === null &&
        templatesError === null &&
        selectedUsers.length !== 0 &&
        selectedTemplates.length !== 0,
    );
  }, [isSending, usersError, templatesError, selectedUsers, selectedTemplates]);

  const handleSend = async (e) => {
    e.preventDefault();
    await sendEmails({
      templateNames: selectedTemplates,
      userEmails: selectedUsers,
    });
  };

  return (
    <>
      <form onSubmit={handleSend}>
        <div>
          See how banners look like in your inbox by sending fake phishing
          emails triggering them.
        </div>
        <div className="mt-4 mb-4">
          {templatesPending ? (
            <span>Loading scenario list...</span>
          ) : templatesError ? (
            <span>Unable to load the scenario list...</span>
          ) : (
            <Tags
              options={templates}
              label={"Phishing scenario"}
              selected={selectedTemplates}
              onChange={(_, newValue) => setSelectedTemplates(newValue)}
            />
          )}
        </div>
        <div className="mt-4 mb-4">
          {usersPending ? (
            <span>Loading user list...</span>
          ) : usersError ? (
            <span>Unable to load the user list...</span>
          ) : (
            <Tags
              options={users.map((u) => u.email)}
              label={"Target users"}
              selected={selectedUsers}
              onChange={(_, newValue) => setSelectedUsers(newValue)}
            />
          )}
        </div>
        <div>
          <Button variant="contained" type="submit" disabled={!buttonActivated}>
            Send sample emails
          </Button>
        </div>
      </form>
    </>
  );
}

export default function Tags({ options, label, onChange, selected }) {
  return (
    <StyledAutocomplete
      multiple
      options={options}
      getOptionLabel={(option) => option}
      filterSelectedOptions
      onChange={onChange}
      value={selected}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}
