import { ButtonSpinner } from "@/components/Spinner";
import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import BootstrapStyleSwitch from "@/components/controls/BootstrapStyleSwitch";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { BannerUser, EngagementStatus } from "@/pages/banners/users/state";
import { IconButton, Tooltip } from "@mui/material";
import ModalEngageAdditionalUsers from "@/pages/banners/users/ModalEngageAdditionalUsers";

const EngageSwitch = ({
  handleEngageOrDisengageUser,
  userIndex,
  engagement_status,
  userEmail,
  isLoadingDisengageResponse,
  canEngageUser,
}: {
  handleEngageOrDisengageUser: (
    userEmail: string,
    engage: boolean,
  ) => Promise<void>;
  userIndex: string;
  engagement_status: EngagementStatus;
  userEmail: string;
  isLoadingDisengageResponse: boolean;
  canEngageUser: boolean;
}) => {
  const isRegularAdminTryingToEngage =
    !canEngageUser && engagement_status === "disengaged";
  const isWaiting =
    engagement_status === "engage_started" ||
    engagement_status === "disengage_started";

  const shouldEngageOnCLick = engagement_status === "disengaged";
  const checked =
    engagement_status === "engaged" || engagement_status === "engage_started";
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <BootstrapStyleSwitch
        id={`engage-disengage-user-${userIndex}`}
        label=""
        disabled={
          isWaiting ||
          isLoadingDisengageResponse ||
          isRegularAdminTryingToEngage
        }
        checked={checked}
        onChange={() => {
          handleEngageOrDisengageUser(userEmail, shouldEngageOnCLick);
        }}
      />
      {isWaiting && <ButtonSpinner size="1rem" color="primary" thickness="8" />}
    </div>
  );
};

export function UsersBannersListTable({
  users,
  handleEngageOrDisengageUser,
  deactivateAllUserActions,
  canEngageUser,
}: {
  users: Array<BannerUser>;
  handleEngageOrDisengageUser: (
    userEmail: string,
    engage: boolean,
  ) => Promise<void>;
  deactivateAllUserActions: boolean;
  canEngageUser: boolean;
}) {
  const columns = [
    {
      name: "firstname",
      label: "Firstname",
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "lastname",
      label: "Lastname",
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "language",
      label: "Language",
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "departments",
      label: "Departments",
      options: {
        customBodyRender: (value) => value.join(", "),
        filter: false,
        sort: true,
        sortCompare: (order) => (v1, v2) => {
          const o = order === "desc" ? 1 : -1;
          let res = 0;

          if (v1.data.length === 0) {
            res -= 1;
          }
          if (v2.data.length === 0) {
            res += 1;
          }

          if (v1.data.length !== 0 && v2.data.length !== 0) {
            res = v1.data[0].localeCompare(v2.data[0]);
          }

          return res * o;
        },
        searchable: true,
      },
    },
    {
      name: "banners_engagement_status",
      label: "Engaged",
      options: {
        filter: false,
        sort: true,
        sortCompare: (order) => (v1, v2) => {
          /* We filter by a precise order, allowing state_started to be displayed first, before finished states.
                              This allows the administrator to see pending actions first. */
          const state_order = {
            disengage_started: 0,
            disengaged: 1,
            engaged: 2,
            engage_started: 3,
          };
          const o = order === "desc" ? 1 : -1;
          const value_v1 = state_order[v1.data];
          const value_v2 = state_order[v2.data];

          return (value_v1 - value_v2) * o;
        },
        searchable: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => (
          <EngageSwitch
            engagement_status={users[dataIndex].banners_engagement_status}
            handleEngageOrDisengageUser={handleEngageOrDisengageUser}
            userIndex={dataIndex}
            userEmail={users[dataIndex].email}
            isLoadingDisengageResponse={deactivateAllUserActions}
            canEngageUser={canEngageUser}
          />
        ),
      },
    },
  ];
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  class CustomToolbar extends React.Component {
    handleClick = () => {
      setConfirmationDialogOpen(true);
    };

    render() {
      return (
        <Tooltip title="Engage 100 users" placement="bottom">
          <IconButton onClick={this.handleClick}>
            <GroupAddIcon />
          </IconButton>
        </Tooltip>
      );
    }
  }

  const options = {
    rowsPerPage,
    onChangeRowsPerPage: setRowsPerPage,
    print: false,
    rowsPerPageOptions: [10, 50, 100, 500],
    selectableRows: "none",
    customToolbar: () => {
      return <CustomToolbar />;
    },
  };

  return (
    <div>
      <MUIDataTable
        marginLeft={50}
        title="Users Banners"
        data={users}
        columns={columns}
        options={options}
      />
      <ModalEngageAdditionalUsers
        confirmationDialogOpen={confirmationDialogOpen}
        setConfirmationDialogOpen={setConfirmationDialogOpen}
        users={users}
      />
    </div>
  );
}
