import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "@mui/material";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "@/components/content/Portlet";
import { Company } from "@/pages/phishing-simulation/company/Company";
import { GroupsDepartments } from "@/pages/general-settings/commonUserProvisioning/GroupsDepartments/GroupsDepartments";
import { DepartmentsSaas } from "@/pages/general-settings/commonUserProvisioning/DepartmentsSaas/DepartmentsSaas";

export default function PhishingSimulationCompany() {
  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (window.location.hash === "#general-informations") {
      setTab(0);
    } else if (window.location.hash === "#groups-departments") {
      setTab(1);
    } else if (window.location.hash === "#template-attribution") {
      setTab(2);
    }
  }, []);

  return (
    <>
      <Portlet>
        <PortletHeader title="Company" />
        <PortletBody>
          <PortletHeaderToolbar>
            <Tabs
              indicatorColor="primary"
              textColor="inherit"
              component="div"
              className="builder-tabs"
              value={tab}
              onChange={(_, nextTab) => {
                if (nextTab === 0) {
                  window.location.hash = "#general-informations";
                } else if (nextTab === 1) {
                  window.location.hash = "#groups-departments";
                } else if (nextTab === 2) {
                  window.location.hash = "#template-attribution";
                }
                setTab(nextTab);
              }}
            >
              <Tab label="General informations" />
              <Tab label="Groups & departments" />
              <Tab label="Template attribution" />
            </Tabs>
          </PortletHeaderToolbar>
        </PortletBody>
      </Portlet>
      {tab === 0 && <Company />}
      {tab === 1 && <GroupsDepartments tab={tab} setTab={setTab} />}
      {tab === 2 && <DepartmentsSaas tab={tab} setTab={setTab} />}
    </>
  );
}
